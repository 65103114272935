import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { PropertyMarket } from 'types/PropertyMarket';
import endpoints from 'utils/apiClient/endpoints';

interface MarketFiltersResponse {
    marketData: PropertyMarket[];
    marketBuckets: Record<string, string[]>;
}

export const searchFiltersApi = createApi({
    reducerPath: 'searchFiltersApi',
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        getMarketFilters: builder.query<MarketFiltersResponse, void>({
            queryFn: async () => {
                try {
                    const urlResponse = await endpoints.searchFilters.signedUrl.get();
                    const url = await urlResponse.text();
                    const response = await fetch(url);
                    const marketData: PropertyMarket[] = await response.json();

                    const marketBuckets = marketData.reduce((acc, { market, submarket }) => {
                        if (!acc[market]) acc[market] = [];
                        if (!acc[market].includes(submarket)) {
                            acc[market].push(submarket);
                        }
                        return acc;
                    }, {} as Record<string, string[]>);

                    return { data: { marketData, marketBuckets } };
                } catch (error) {
                    return { error: { status: 'CUSTOM_ERROR', error: (error as Error).message } };
                }
            },
        }),
    }),
});

export const { useGetMarketFiltersQuery } = searchFiltersApi;
