import Color from '@arcgis/core/Color';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import UniqueValue from '@arcgis/core/renderers/support/UniqueValue';
import UniqueValueClass from '@arcgis/core/renderers/support/UniqueValueClass';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import SketchEdges3D from '@arcgis/core/symbols/edges/SketchEdges3D';
import SolidEdges3D from '@arcgis/core/symbols/edges/SolidEdges3D';
import FillSymbol3DLayer from '@arcgis/core/symbols/FillSymbol3DLayer';
import MeshSymbol3D from '@arcgis/core/symbols/MeshSymbol3D';
import PointSymbol3D from '@arcgis/core/symbols/PointSymbol3D';
import PolygonSymbol3D from '@arcgis/core/symbols/PolygonSymbol3D';
import { filter, find, isNil } from 'lodash';

import { EdgeStyle } from 'store/featuresSlice';
import { buildingGeometryRefIdOfType } from 'types/Layers/BuildingGeometryRef';
import {
    OsmHighlightSetBuilding,
    treeItemKeyForBuilding,
} from 'types/Layers/OsmHighlightSetMetadata';
import { OSM_EDGE_STYLE, OSM_POLYGON_COLOR } from './osmBuildingLayerHelper';
import { createOpacityVisualVariable } from './visualVariableHelper';

export const createUniqueValueGroup = (
    values: number[],
    key: string,
    symbol: MeshSymbol3D | PointSymbol3D | PolygonSymbol3D,
    label: string
) => {
    const classes = [createUniqueValueClass(values, symbol, label)] as UniqueValueClass[];
    const uniqueGroup = new UniqueValueGroup({
        classes,
    });
    uniqueGroup.set('key', key);
    return uniqueGroup;
};

export const createUniqueValueClass = (
    values: number[],
    symbol: MeshSymbol3D | PointSymbol3D | PolygonSymbol3D,
    label?: string
) => {
    return new UniqueValueClass({
        label: label,
        symbol,
        values: values?.map((value) => new UniqueValue({ value: value })),
    });
};

function reverseMapFilter<T>(arr: T[], map: (t: T) => T | undefined) {
    const result: T[] = [];

    for (let i = arr.length - 1; i >= 0; i--) {
        const mapResult = map(arr[i]);
        if (typeof mapResult !== 'undefined') {
            result.push(mapResult);
        }
    }

    // Un-reverse results since they were added in backwards
    return result.reverse();
}

/**
 * Creates a deduped (deduplicated) copy of an array of UniqueValueGroups
 * keeping only the last occurrence of each distinct UniqueValue.
 *
 * Keeping the last occurrence emulates the behavior of latter symbols
 * overriding earlier ones in the array.
 *
 * This function also removes empty classes and groups since they will have
 * no affect on the visualization.
 * @param groups The unique value group array with possible duplicate values.
 */
export function dedupUvgValues(
    groups: __esri.UniqueValueGroupProperties[]
): __esri.UniqueValueGroupProperties[] {
    const found = new Set<string | number>();
    return reverseMapFilter(groups, (g) => {
        const newClasses = dedupUvcValues(g.classes, found);

        return newClasses != null && newClasses.length ? { ...g, classes: newClasses } : undefined;
    });
}

function dedupUniqueValues(
    values: string | number | __esri.UniqueValueProperties[] | undefined,
    found: Set<string | number>
) {
    if (!values) {
        return [];
    }

    if (Array.isArray(values)) {
        return reverseMapFilter(values, (v) => {
            if (v.value == null || found.has(v.value)) {
                return undefined;
            } else {
                found.add(v.value);
                return v;
            }
        });
    }

    if (!found.has(values)) {
        found.add(values);
        return values;
    }

    return undefined;
}

function dedupUvcValues(
    classes: __esri.UniqueValueClassProperties[] | undefined,
    found: Set<string | number>
) {
    if (!classes) {
        return [];
    }

    return reverseMapFilter(classes, (c) => {
        const newValues = dedupUniqueValues(c.values, found);
        return newValues != null && (!Array.isArray(newValues) || newValues.length)
            ? { ...c, values: newValues }
            : undefined;
    });
}

export const addOsmPointsToGraphics = (buildings: OsmHighlightSetBuilding[]) => {
    const pinGraphics = [] as Graphic[];
    buildings.forEach((building: OsmHighlightSetBuilding) => {
        pinGraphics.push(
            new Graphic({
                geometry: new Point(),
                attributes: { OSMID: buildingGeometryRefIdOfType(building.geometryRef, 'osm') },
            })
        );
    });
    return pinGraphics;
};

const getEdgeStyle = ({ size, visible, type, color }: EdgeStyle) => {
    if (visible) {
        if (type == 'solid')
            return new SolidEdges3D({
                color: color,
                size: size,
            });
        else if (type == 'sketch') {
            return new SketchEdges3D({
                color: color,
                size: size,
            });
        }
    }
};

export const createOsmMeshSymbol3D = (
    color: number[] | string | Color = OSM_POLYGON_COLOR,
    edge = OSM_EDGE_STYLE
) => {
    const edgeStyle = getEdgeStyle(edge);
    return new MeshSymbol3D({
        symbolLayers: [
            new FillSymbol3DLayer({
                material: {
                    color,
                    colorMixMode: 'replace',
                },
                edges: edgeStyle,
            }),
        ],
    });
};

interface CreateUniqueValueGroupsRendererOptions {
    defaultColor?: Color;
    edge?: EdgeStyle;
    field?: string;
}

export const createOSMUniqueValueGroupsRenderer = (
    uniqueValueGroups: UniqueValueGroup[],
    {
        defaultColor = OSM_POLYGON_COLOR,
        edge = OSM_EDGE_STYLE,
        field = 'OSMID',
    }: CreateUniqueValueGroupsRendererOptions = {}
) => {
    return new UniqueValueRenderer({
        field: field,
        defaultSymbol: createOsmMeshSymbol3D(defaultColor, edge),
        uniqueValueGroups,
        visualVariables: [createOpacityVisualVariable()],
    });
};

export const createDevPipelineUniqueValueGroupsRenderer = (
    uniqueValueGroups: UniqueValueGroup[],
    { field = 'BlackbirdId' }: CreateUniqueValueGroupsRendererOptions = {}
) => {
    return new UniqueValueRenderer({
        field: field,
        uniqueValueGroups,
        visualVariables: [createOpacityVisualVariable()],
    });
};

export const addOsmUniqueValueGroupToRenderer = (
    renderer: UniqueValueRenderer,
    symbol: MeshSymbol3D | PointSymbol3D,
    key: string,
    values: number[],
    defaultColor = OSM_POLYGON_COLOR,
    label: string
) => {
    //remove group with the same key
    renderer.uniqueValueGroups = filter(
        renderer.uniqueValueGroups,
        (obj) => 'key' in obj && !isNil(obj.key) && obj.key !== key
    );
    const uniqueGroup = createUniqueValueGroup(values, key, symbol, label);
    const groups = [...renderer.uniqueValueGroups, uniqueGroup];
    return createOSMUniqueValueGroupsRenderer(groups, { defaultColor });
};

export const findUniqueGroupFromRenderer = (renderer: UniqueValueRenderer, key: string) => {
    return find(renderer.uniqueValueGroups, { key: key }) as UniqueValueGroup;
};

export const removeUniqueGroupFromRenderer = (
    layer: SceneLayer | FeatureLayer,
    key: string,
    defaultColor = OSM_POLYGON_COLOR
) => {
    const renderer = layer.renderer as UniqueValueRenderer;
    const groups = filter(
        renderer.uniqueValueGroups,
        (obj) => 'key' in obj && !isNil(obj.key) && obj.key !== key
    );
    layer.renderer = createOSMUniqueValueGroupsRenderer(groups as UniqueValueGroup[], {
        defaultColor,
    });
};

export const getUniqueValuesFromRenderer = (renderer: UniqueValueRenderer) => {
    const valuesArray: (string | number)[] = [];
    for (const group of renderer.uniqueValueGroups) {
        if (group.classes) {
            for (const classObj of group.classes) {
                if (classObj.values) {
                    for (const valueObj of classObj.values) {
                        if (valueObj.value) {
                            valuesArray.push(valueObj.value);
                        }
                    }
                }
            }
        }
    }
    return valuesArray;
};

export function isBuildingChecked(
    checkedKeyLookup: Record<string, string>,
    parentKey: string,
    building: OsmHighlightSetBuilding
): boolean {
    return !!checkedKeyLookup[treeItemKeyForBuilding(parentKey, building)];
}
