import Color from '@arcgis/core/Color';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import Query from '@arcgis/core/rest/support/Query';

import { DevPipelineHighlightBuildingEntry } from 'components/map/controllers/DevPipelineController';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import isDefined from 'utils/isDefined';
import { MarketViewTreeItem } from '../types/Layers/LibraryLayerTreeItem';
import { MarketViewLayerConditions } from '../types/Layers/MarketViewLayerMetadata';
import { nanoid } from '../utils/idUtils';
import {
    createDevPipelineUniqueValueGroupsRenderer,
    createOsmMeshSymbol3D,
    createUniqueValueGroup,
} from './osmStylesHelper';
import {
    PROPERTIES_SEARCH_MATCHED_UVGROUP_COLOR,
    PROPERTIES_SEARCH_UNMATCHED_UVGROUP_DEFAULT_COLOR,
} from './searchHelper';

export const DEV_PIPELINE_SCENE_LAYER_ID = 'devpipeline-scene-layer';
export const DEV_PIPELINE_PINS_ID = 'devpipeline-scene-pins-layer';
export const DEV_PIPELINE_HIGHLIGHT_SCENE_LAYER_ID = DEV_PIPELINE_SCENE_LAYER_ID + '2';

export const getDevPipelineSourceByLayerId = (layerId: string) =>
    layerId == DEV_PIPELINE_HIGHLIGHT_SCENE_LAYER_ID ? 'highlighted-dev-pipeline' : 'dev-pipeline';

export const BUILDING_COLOR = new Color(PROPERTIES_SEARCH_UNMATCHED_UVGROUP_DEFAULT_COLOR);

export const createDevPipelineViewLibraryLayerItem = (
    conditions?: MarketViewLayerConditions,
    markets?: string[]
): MarketViewTreeItem => {
    return {
        key: `PropertyView--${nanoid()}`,
        id: 0,
        title: 'Development Pipeline View',
        itemType: 'PropertyView',
        checked: true,
        metadata: {
            name: 'Development Pipeline View',
            conditions: conditions,
            markets: markets,
            fieldName: 'devPipeline',
        },
    } as MarketViewTreeItem;
};

export const queryDevPipelineFeatures = async (
    query: Query,
    field = 'MarketSpherePropertyId'
): Promise<number[]> => {
    const layer = findMapLayer(DEV_PIPELINE_SCENE_LAYER_ID) as SceneLayer;
    if (!layer) return [];

    const { geometry, where, outFields } = query;
    const result = await layer.queryFeatures({
        geometry,
        where,
        outFields,
        spatialRelationship: 'intersects',
    });

    if (!result?.features) return [];

    return result.features.map((feature) => Number(feature.attributes[field])).filter(isFinite);
};

export const findMarketSphereIdFromBlackbirdId = async (
    bbId: number
): Promise<number | undefined> => {
    const query = new Query({
        where: `BlackbirdId = ${bbId}`,
        returnGeometry: false,
        outFields: ['MarketSpherePropertyId'],
    });

    const features: number[] = await queryDevPipelineFeatures(query, 'MarketSpherePropertyId');
    return features[0];
};

export const findBlackbirdIdFromDevPipeline = async (
    marketSphereIds: number
): Promise<number | undefined> => {
    const query = new Query({
        where: `MarketSpherePropertyId = ${marketSphereIds}`,
        returnGeometry: false,
        outFields: ['BlackbirdId'],
    });

    const features: number[] = await queryDevPipelineFeatures(query, 'BlackbirdId');
    return features[0];
};

export const queryDevPipelineFeatureAttributes = async (query: Query) => {
    const layer = findMapLayer(DEV_PIPELINE_SCENE_LAYER_ID) as SceneLayer;

    if (!layer) return [];
    const { geometry, where, outFields } = query;
    const result = await layer.queryFeatures({
        geometry,
        where,
        outFields,
        spatialRelationship: 'intersects',
    });
    if (!result) return [];

    return result.features.map((feature) => feature.attributes).filter(isDefined);
};

export function devPipelineHighlightRenderer(
    entries: Map<string, DevPipelineHighlightBuildingEntry>,
    searchResultsBlackbirdIds: number[]
) {
    const buildingColor = new Color(PROPERTIES_SEARCH_UNMATCHED_UVGROUP_DEFAULT_COLOR);
    const searchResultColor = new Color(PROPERTIES_SEARCH_MATCHED_UVGROUP_COLOR);

    const highlightUniqueValueGroups = [...entries.values()].map((entry) => {
        return createUniqueValueGroup(
            entry.blackbirdIds,
            'BlackbirdId',
            createOsmMeshSymbol3D(new Color(entry.style.color)),
            'Highlight Sets'
        );
    });

    const searchUniqueValueGroup =
        searchResultsBlackbirdIds.length > 0
            ? [
                  createUniqueValueGroup(
                      searchResultsBlackbirdIds,
                      'search-results',
                      createOsmMeshSymbol3D(searchResultColor),
                      'Search Results'
                  ),
              ]
            : [];

    return createDevPipelineUniqueValueGroupsRenderer(
        [...searchUniqueValueGroup, ...highlightUniqueValueGroups],
        {
            field: 'BlackbirdId',
            defaultColor: buildingColor,
            edge: undefined,
        }
    );
}
