import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';

import {
    BuildingIdentity,
    BuildingPropertyDetails,
    BuildingRepresentationDetails,
    StructureDetails,
} from 'types/buildingIdentity';

////
////
////    Queries
////
////

export const fetchFeaturesFromLayerView = async (layerView: FeatureLayerView) => {
    const res = await layerView.queryFeatures({
        returnGeometry: true,
        outFields: ['*'],
    });

    return featuresToBuildingIdentities(res.features);
};

export const watchLayerViewDataUpdating = (layerView: FeatureLayerView, callback: () => void) => {
    const watchHandle = reactiveUtils.when(
        () => layerView.dataUpdating === false,
        () => callback()
    );
    return watchHandle;
};
////
////
////    Data transformation
////
////

export const featuresToBuildingIdentities = (features: Graphic[]): BuildingIdentity[] => {
    return features.map(featureToBuildingIdentity);
};

////
////
////    Internal helper methods
////
////

const featureToBuildingIdentity = (feature: Graphic): BuildingIdentity => {
    return {
        buildingId: feature.getAttribute('building_id'),
        structure: featureToStructureDetails(feature),
        property: featureToBuildingPropertyDetails(feature),
        representation: featureToRepresentationDetails(feature),
    };
};

const featureToBuildingPropertyDetails = (feature: Graphic): BuildingPropertyDetails => {
    if (feature.getAttribute('property_type') !== 'marketsphere') {
        throw new Error('Not yet implemented');
    }
    return {
        id: feature.getAttribute('property_id'),
        type: 'marketsphere',
    };
};
const featureToRepresentationDetails = (
    feature: Graphic
): BuildingRepresentationDetails | undefined => {
    const repType = featureToRepresentationType(feature);

    switch (repType) {
        case 'osm':
            return {
                id: feature.getAttribute('osm_id'),
                type: 'osm',
            };
        case 'buildingEdits':
        case 'devPipeline':
            throw new Error('Not yet implemented');
        case 'none':
            return undefined;
    }
};

const featureToStructureDetails = (feature: Graphic): StructureDetails => {
    const geom = feature.geometry as Point;
    const height_m = feature.getAttribute('building_height_m');

    if (!geom) {
        throw new Error('Could not retrieve the feature geometry');
    }

    return {
        center: geom,
        height_m,
    };
};

function featureToRepresentationType(feature: Graphic) {
    if (feature.getAttribute('hasBuildingEdits') === 1) {
        return 'buildingEdits';
    } else if (feature.getAttribute('hasDevPipeline') === 1) {
        return 'devPipeline';
    } else if (feature.getAttribute('hasOSM') === 1) {
        return 'osm';
    } else {
        return 'none';
    }
}
