import { get, has, set } from 'lodash';

import { SearchItemTypes } from 'constants/search.constants';
import { FilterTypes, isMinMax } from 'helpers/searchHelper';
import {
    CspAvailabilityInput,
    CspAvailabilityInputSchema,
} from 'types/cspInputSchemas/CspAvailabilityInputSchema';
import { CspBuildingClass } from 'types/cspInputSchemas/CspFieldsSchemas';
import { CspLeaseInput, CspLeaseInputSchema } from 'types/cspInputSchemas/CspLeaseInputSchema';
import { ListOfMarketHierarchyParameterInput } from 'types/cspInputSchemas/CspMarketsHierarchySchema';
import {
    CspPropertyInput,
    CspPropertyInputSchema,
} from 'types/cspInputSchemas/CspPropertyInputSchema';
import { CspSaleInput, CspSaleInputSchema } from 'types/cspInputSchemas/CspSaleInputSchema';
import { AvailabilityFilters } from 'types/searchSchemas/AvailabilityFiltersSchema';
import { LeaseFilters } from 'types/searchSchemas/LeaseFiltersSchema';
import { NumericRangeInput } from 'types/searchSchemas/NumericRangeInputSchema';
import { PropertyFilters } from 'types/searchSchemas/PropertyFiltersSchema';
import { SaleFilters } from 'types/searchSchemas/SaleFiltersSchema';
import { BuildingClass, PropertyType } from 'types/searchSchemas/SearchFieldSchemas';

const convertMinMaxToNumericRange = (minMax?: {
    min?: number;
    max?: number;
}): NumericRangeInput | undefined => {
    if (!minMax) return undefined;
    return {
        from: minMax.min ?? undefined,
        to: minMax.max ?? undefined,
    };
};

const convertFieldsToCsp = (filters: FilterTypes, omit: string[] = []) => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
        if (omit.includes(key)) {
            return acc;
        } else if (isMinMax(value)) {
            acc[key] = convertMinMaxToNumericRange(value);
        } else {
            acc[key] = value;
        }
        return acc;
    }, {} as Record<string, unknown>);
};

const buildingClassToCsp = (items: BuildingClass[]) => {
    return items.map((item) => {
        return item.replace('Class ', '').trim() as CspBuildingClass;
    });
};

const overridePropertyInputForSubQuery = (
    input: CspPropertyInput,
    overrides: Record<string, keyof CspPropertyInput>
) => {
    Object.entries(overrides).forEach(([key, value]) => {
        const keyPath = key.split('.');
        if (has(input, key)) {
            set(input as Record<string, unknown>, value, get(input, key));
            delete input[keyPath[0] as keyof typeof input];
        }
    });
};

const mapPropertyFiltersToCsp = (
    filters: PropertyFilters,
    marketHierarchy: ListOfMarketHierarchyParameterInput,
    propertyTypes: PropertyType[]
): CspPropertyInput => {
    const cspInput: CspPropertyInput = {};

    if (filters.inStatistics) {
        cspInput.indicator = {
            values: [
                {
                    inStatistics: filters.inStatistics.includes('Yes'),
                },
            ],
        };
    }

    if (filters.buildingSubtype) {
        cspInput.usage = {
            values: [
                {
                    subType: filters.buildingSubtype,
                },
            ],
        };
    }

    if (filters.buildingOwner) {
        cspInput.companies = {
            values: [
                {
                    companyRole: 'building owner',
                    companyName: filters.buildingOwner,
                },
            ],
        };
    }

    if (filters.buildingClass) {
        cspInput.construction = {
            values: {
                buildingClass: buildingClassToCsp(filters.buildingClass),
            },
        };
    }

    const convertedFields = convertFieldsToCsp(filters, [
        'inStatistics',
        'buildingSubtype',
        'buildingOwner',
        'buildingClass',
    ]);

    const inputFilters = {
        marketsHierarchy: marketHierarchy,
        propertyTypeSourceValue: propertyTypes,
        ...cspInput,
        ...convertedFields,
    };

    return CspPropertyInputSchema.parse(inputFilters);
};

const mapAvailabilityFiltersToCsp = (
    filters: AvailabilityFilters,
    marketHierarchy: ListOfMarketHierarchyParameterInput,
    propertyTypes: PropertyType[]
): CspAvailabilityInput => {
    const { properties, ...rest } = filters;
    const cspInput = convertFieldsToCsp(rest, ['properties']);
    const propertiesInput = mapPropertyFiltersToCsp(
        properties ?? {},
        marketHierarchy,
        propertyTypes
    );

    overridePropertyInputForSubQuery(propertiesInput, {
        propertyStatus: 'buildingStatus',
        ['construction.values.buildingClass']: 'buildingClass',
        propertyTypeSourceValue: 'propertyType',
    } as Record<string, keyof CspPropertyInput>);

    const inputFilters: CspAvailabilityInput = {
        properties: {
            values: [propertiesInput],
        },
        ...cspInput,
    };

    return CspAvailabilityInputSchema.parse(inputFilters);
};

const mapLeaseFiltersToCsp = (
    filters: LeaseFilters,
    marketHierarchy: ListOfMarketHierarchyParameterInput,
    propertyTypes: PropertyType[]
): CspLeaseInput => {
    const { properties, ...rest } = filters;
    const cspInput = convertFieldsToCsp(rest, ['properties', 'companies']);
    const propertiesInput = mapPropertyFiltersToCsp(
        properties ?? {},
        marketHierarchy,
        propertyTypes
    );

    if (filters.tenants || filters.industryClusters) {
        cspInput.companies = {
            values: filters.industryClusters?.length
                ? filters.industryClusters.map((industryCluster) => ({
                      industryCluster,
                      companyName: filters.tenants || [],
                  }))
                : [
                      {
                          companyName: filters.tenants || [],
                      },
                  ],
        };
    }

    overridePropertyInputForSubQuery(propertiesInput, {
        ['construction.values.buildingClass']: 'buildingClass',
    } as Record<string, keyof CspPropertyInput>);

    const inputFilters: CspLeaseInput = {
        properties: {
            values: [propertiesInput],
        },
        ...cspInput,
    };

    return CspLeaseInputSchema.parse(inputFilters);
};

const mapSalesFiltersToCsp = (
    filters: SaleFilters,
    marketHierarchy: ListOfMarketHierarchyParameterInput,
    propertyTypes: PropertyType[]
): CspSaleInput => {
    const { properties, ...rest } = filters;
    const cspInput = convertFieldsToCsp(rest, ['properties']);
    const propertiesInput = mapPropertyFiltersToCsp(
        properties ?? {},
        marketHierarchy,
        propertyTypes
    );

    overridePropertyInputForSubQuery(propertiesInput, {
        ['construction.values.buildingClass']: 'buildingClass',
        propertyStatus: 'buildingStatus',
        propertyTypeSourceValue: 'propertyType',
    } as Record<string, keyof CspPropertyInput>);

    const inputFilters: CspSaleInput = {
        properties: {
            values: [propertiesInput],
        },
        ...cspInput,
    };

    return CspSaleInputSchema.parse(inputFilters);
};

// Central Mapping Function
export const mapFiltersToCspInputs = (
    filters: LeaseFilters | SaleFilters | AvailabilityFilters | PropertyFilters,
    type: SearchItemTypes,
    marketHierarchy: ListOfMarketHierarchyParameterInput,
    propertyTypes: PropertyType[]
) => {
    switch (type) {
        case 'availabilities':
            return mapAvailabilityFiltersToCsp(
                filters as AvailabilityFilters,
                marketHierarchy,
                propertyTypes
            );
        case 'leaseComps':
            return mapLeaseFiltersToCsp(filters as LeaseFilters, marketHierarchy, propertyTypes);
        case 'sales':
            return mapSalesFiltersToCsp(filters as SaleFilters, marketHierarchy, propertyTypes);
        case 'properties':
            return mapPropertyFiltersToCsp(
                filters as PropertyFilters,
                marketHierarchy,
                propertyTypes
            );
        default:
            throw new Error('Unsupported filter type');
    }
};
