import SceneLayer from '@arcgis/core/layers/SceneLayer';

import { GoToTarget3DOptions } from '../types/GoToTarget3DOptions';
import { findMapLayer } from '../utils/esri/findMapLayerUtils';
import { DEV_PIPELINE_SCENE_LAYER_ID } from './devPipelineHelper';

export const zoomToDevPipelineBuilding = (
    setGoTo: (target: GoToTarget3DOptions) => void,
    buildingId: number
) => {
    const layer = findMapLayer(DEV_PIPELINE_SCENE_LAYER_ID) as SceneLayer;
    if (!layer) return;
    const query = layer.createQuery();
    query.where = `BlackbirdId = ${buildingId}`;
    layer.queryExtent(query).then((result) => {
        if (result.extent) {
            setGoTo({
                target: result.extent,
                zoom: 18,
            });
        }
    });
};

export function isDevPipelineSceneLayer(layerId: string): boolean {
    return [DEV_PIPELINE_SCENE_LAYER_ID, DEV_PIPELINE_SCENE_LAYER_ID + '2'].includes(layerId);
}
