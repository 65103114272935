import { useCallback, useEffect } from 'react';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SceneView from '@arcgis/core/views/SceneView';

import { watchLayerViewDataUpdating } from 'helpers/buildingIdentityHelper';
import { useInitialValue } from 'hooks/useInitialValue';
import { fetchBuildingIdentities } from 'store/buildingIdentitySlice';
import { useAppDispatch } from 'types/hooks';
import config from 'utils/config';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';

export const BUILDING_IDENTITY_LAYER_ID = 'identity-layer';

export const getBuildingIdentityLayer = (): FeatureLayer | undefined => {
    return findMapLayer(BUILDING_IDENTITY_LAYER_ID) as FeatureLayer;
};

type BuildingIdentityControllerProps = {
    view: SceneView;
};

export const BuildingIdentityController = ({ view }: BuildingIdentityControllerProps) => {
    const dispatch = useAppDispatch();
    const layer = useInitialValue(() => getBuildingIdentityLayer() ?? createIdentityLayer());

    const initialize = useCallback(async () => {
        view.map.add(layer);
        const lv = await view.whenLayerView(layer);
        const handle = watchLayerViewDataUpdating(lv, () =>
            dispatch(fetchBuildingIdentities({ view }))
        );
        lv.addHandles(handle);
    }, [dispatch, layer, view]);

    useEffect(() => {
        if (!getBuildingIdentityLayer()) {
            initialize();
        }
    }, [initialize]);

    return null;
};

const createIdentityLayer = () =>
    new FeatureLayer({
        url: config.buildingIdentityFeatureServiceUrl,
        title: 'Building Identity',
        id: BUILDING_IDENTITY_LAYER_ID,
        legendEnabled: false,
        popupEnabled: false,
        outFields: ['*'],
        hasZ: true,
        returnZ: true,
        elevationInfo: {
            mode: 'on-the-ground',
        },
    });
