import Color from '@arcgis/core/Color';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { compact, keyBy, map } from 'lodash';

import isDefined from 'utils/isDefined';
import {
    isBuildingWithRefType,
    OsmHighlightSetBuilding,
    OsmHighlightSetMetadata,
} from '../types/Layers/OsmHighlightSetMetadata';
import { findMapLayer } from '../utils/esri/findMapLayerUtils';
import {
    createGenericIconRenderer,
    createNumberedIconRenderer,
    refreshLayer,
} from './osmHighlightSetHelper';
import { createPoint3dSymbolCallout } from './polygonLayerHelper';

export const BUILDING_EDITS_PINS_LAYER_ID = 'building-edits-pins-layer';

export const createPinsLayerUniqueValueGroupsRenderer = (uniqueValueGroups: UniqueValueGroup[]) => {
    return new UniqueValueRenderer({
        field: 'blackbirdId',
        defaultSymbol: createPoint3dSymbolCallout(),
        uniqueValueGroups: uniqueValueGroups,
    });
};

const addedPinGraphicIds = {
    _map: new WeakMap<FeatureLayer, Set<number>>(),
    _getBbIdSet(featureLayer: FeatureLayer) {
        let bbIdSet = this._map.get(featureLayer);
        if (!bbIdSet) {
            this._map.set(featureLayer, (bbIdSet = new Set()));
        }
        return bbIdSet;
    },
    hasBbId(featureLayer: FeatureLayer, bbId: number): boolean {
        const bbIdSet = this._getBbIdSet(featureLayer);
        return bbIdSet.has(bbId);
    },
    addBbIds(featureLayer: FeatureLayer, bbIds: number[]): void {
        const bbIdSet = this._getBbIdSet(featureLayer);
        for (const bbId of bbIds) {
            bbIdSet.add(bbId);
        }
    },
    removeBbIds(featureLayer: FeatureLayer, bbIds: number[]): void {
        const bbIdSet = this._getBbIdSet(featureLayer);
        for (const bbId of bbIds) {
            bbIdSet.delete(bbId);
        }
    },
};

export const deleteHighlightSetPinsOnMap = async (
    highlightSetKey: string,
    pinsLayer: FeatureLayer,
    deleteBuildingIds: number[]
) => {
    if (!deleteBuildingIds.length) return;
    const deleteFeatures = await pinsLayer.queryFeatures({
        where: `BlackbirdId IN (${deleteBuildingIds})`,
    });
    await pinsLayer.applyEdits({ deleteFeatures: deleteFeatures.features });
    addedPinGraphicIds.removeBbIds(pinsLayer, deleteBuildingIds);
};

export const addBBHighlightPins = async (
    key: string,
    pinsLayer: FeatureLayer,
    buildingIds: number[],
    styleProperties: OsmHighlightSetMetadata,
    defaultColor: Color
) => {
    if (!buildingIds.length) return;
    const { pinType, buildings } = styleProperties;
    try {
        const filtered = buildingIds.filter((id) => !addedPinGraphicIds.hasBbId(pinsLayer, id));
        addedPinGraphicIds.addBbIds(pinsLayer, buildingIds);
        if (filtered.length) {
            const metadataByDrawnBuildingId = keyBy(
                buildings.filter((building) =>
                    isBuildingWithRefType(building, 'building-edits-layer')
                ),
                (building) => building.geometryRef.id
            );
            const filteredMetadata = buildingIds
                .map((id) => metadataByDrawnBuildingId[id])
                .filter(isDefined);
            const graphics = getGraphicsFromHighlightSetBuildings(filteredMetadata, key);
            await pinsLayer.applyEdits({ addFeatures: graphics });
        }

        //apply pin styles
        let renderer: UniqueValueRenderer;
        if (pinType === 'generic') {
            renderer = await createGenericIconRenderer(
                pinsLayer,
                buildingIds,
                key,
                styleProperties,
                defaultColor
            );
        } else {
            renderer = await createNumberedIconRenderer(
                pinsLayer,
                buildingIds,
                key,
                styleProperties,
                'building-edits-layer'
            );
        }

        pinsLayer.renderer = createPinsLayerUniqueValueGroupsRenderer(renderer.uniqueValueGroups);
        refreshLayer(pinsLayer);
    } catch (error) {
        console.error('Error adding building bdits highlight pins:', error);
    }
};

export const getGraphicsFromHighlightSetBuildings = (
    metadataBuildings: OsmHighlightSetBuilding[],
    key: string
): Graphic[] => {
    return compact(
        map(metadataBuildings, (building) => {
            if (!building.customPosition) return undefined;
            const { latitude, longitude } = building.customPosition;
            if (latitude && longitude) {
                return new Graphic({
                    geometry: new Point({ latitude, longitude }),
                    attributes: {
                        blackbirdId: building.geometryRef?.id,
                        height: 50,
                        // This attribute does not get added since it
                        // is not in the feature layers field list
                        highlightLayerKey: key,
                    },
                });
            }
            return undefined;
        })
    );
};

export const createBuildingEditsPinLayer = async () => {
    const pinLayer = findMapLayer(BUILDING_EDITS_PINS_LAYER_ID) as FeatureLayer;
    if (pinLayer) return pinLayer;
    return new FeatureLayer({
        source: [],
        objectIdField: 'OBJECTID',
        id: BUILDING_EDITS_PINS_LAYER_ID,
        fields: [
            {
                name: 'OBJECTID',
                type: 'oid',
            },
            {
                name: 'blackbirdId',
                type: 'long',
            },
        ],
        outFields: ['blackbirdId'],
        elevationInfo: {
            mode: 'relative-to-scene',
        },
        geometryType: 'point',
        renderer: createPinsLayerUniqueValueGroupsRenderer([] as UniqueValueGroup[]),
        hasZ: true,
        legendEnabled: false,
        popupEnabled: false,
        spatialReference: {
            wkid: 4326,
        },
        visible: true,
    });
};
