import Color from '@arcgis/core/Color';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import SceneView from '@arcgis/core/views/SceneView';

import { EdgeStyle } from 'store/featuresSlice';
import { createOSMUniqueValueGroupsRenderer } from './osmStylesHelper';

export const OSM_POLYGON_HEX_COLOR = '#FFFFFF';
export const OSM_POLYGON_COLOR = new Color(OSM_POLYGON_HEX_COLOR);
export const OSM_SCENE_LAYER_ID = 'osm-scene-layer';
export const OSM_PINS_LAYER_ID = 'osm-highlight-pins';

export const OSM_WALL_COLOR = new Color('#00fffb');
export const OSM_EDGE_STYLE: EdgeStyle = {
    visible: true,
    size: 0.25,
    type: 'solid',
    color: '#000000',
};

export function isOsmSceneLayer(layerId: string): boolean {
    return layerId === OSM_SCENE_LAYER_ID;
}

export const getOsmBuildingsLayer = (view: SceneView) => {
    return view.map.findLayerById(OSM_SCENE_LAYER_ID) as SceneLayer | undefined;
};

export const createOsmBuildingLayer = (color: Color): SceneLayer => {
    return new SceneLayer({
        id: OSM_SCENE_LAYER_ID,
        url: 'https://basemaps3d.arcgis.com/arcgis/rest/services/OpenStreetMap3D_Buildings_v1/SceneServer',
        popupEnabled: false,
        outFields: ['*'],
        legendEnabled: false,
        renderer: createOSMUniqueValueGroupsRenderer([] as UniqueValueGroup[], {
            defaultColor: color,
        }),
        title: 'OpenStreetMap Buildings',
    });
};
