/* eslint-disable @typescript-eslint/no-explicit-any -- Legacy use of any */
import Extent from '@arcgis/core/geometry/Extent';
import Geometry from '@arcgis/core/geometry/Geometry';
import Layer from '@arcgis/core/layers/Layer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import Renderer from '@arcgis/core/renderers/Renderer';
import CIMSymbol from '@arcgis/core/symbols/CIMSymbol';
import FillSymbol3DLayer from '@arcgis/core/symbols/FillSymbol3DLayer';
import PolygonSymbol3D from '@arcgis/core/symbols/PolygonSymbol3D';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import { get, isEmpty } from 'lodash';

import { FetchLayerResult } from 'api/kmlApi';
import ExtendedLayer from 'types/esri/ExtendedLayer';
import { nanoid } from 'utils/idUtils';
import { LibraryMapItLayerSaveState } from '../types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem, { MapItLayerTreeItem } from '../types/Layers/LibraryLayerTreeItem';
import MapItLayerMetadata from '../types/Layers/MapItLayerMetadata';
import { createLayerFromPortalItem, sendRequest } from '../utils/esriUtils';

const allowedGroupsLength = 150;

export interface GISUserGroup {
    id: string;
}

export interface GISUser {
    id: string;
    username: string;
    groups: GISUserGroup[];
}

const layerTypes =
    ' orgid:cGUu4IVTSN4USSSj ((type:"Scene Service" OR type:"Feature Collection" OR type:"Route Layer" OR type:"Layer" OR type:"Explorer Layer" OR type:"Feature Service" OR type:"Stream Service" OR type:"Feed" OR type:"Map Service" OR type:"Vector Tile Service" OR type:"Image Service" OR type:"WMS" OR type:"WFS" OR type:"WMTS" OR type:"KML" OR typekeywords:"OGC" OR typekeywords:"Geodata Service" OR type:"Globe Service" OR type:"CSV" OR type:"Shapefile" OR type:"GeoJson" OR type:"File Geodatabase" OR type:"CAD Drawing" OR type:"Relational Database Connection" OR type:"GeoPackage") -type:("Web Mapping Application" OR "Geodata Service"))  -type:"Code Attachment" -type:"Featured Items" -type:"Symbol Set" -type:"Color Set" -type:"Windows Viewer Add In" -type:"Windows Viewer Configuration" -type:"Map Area" -typekeywords:"MapAreaPackage" -type:"Indoors Map Configuration" -typekeywords:"SMX"';

const createGroupsQuery = (user: GISUser) => {
    const groups = user?.groups?.filter((group) => group.id && group.id !== '');
    if (!groups || groups.length === 0) {
        return null;
    }
    // BB-10126 - We are going to limit the groups length for search query to 150. If user could not find the layer in search list, they can always search it by URL.
    const slicedGroups =
        groups.length > allowedGroupsLength ? groups.slice(0, allowedGroupsLength) : groups;
    const groupsQuery = 'group:(' + slicedGroups.map((group) => group.id).join(' OR ') + ')';
    return groupsQuery;
};

const userLayers = (searchString: string, gisUser: GISUser) => {
    const _userContentQuery = 'owner:' + gisUser.username;
    return `${searchString} ${_userContentQuery} ${layerTypes}`;
};

const userGroupLayers = (searchString: string, gisUser: GISUser) => {
    if (gisUser?.groups?.length > 0)
        return `${searchString} ${createGroupsQuery(gisUser)} ${layerTypes}`;
};

export const buildQuery = (searchString: string, layerFilterType: string, gisUser?: GISUser) => {
    if (!gisUser && layerFilterType === 'mapit-my') {
        return;
    } else if (gisUser && layerFilterType === 'mapit-my') {
        return userLayers(searchString, gisUser);
    } else if (gisUser && layerFilterType === 'mapit-group') {
        return userGroupLayers(searchString, gisUser);
    } else if (layerFilterType === 'mapit-org' || layerFilterType === 'mapit-geolib') {
        return `${
            !isEmpty(searchString)
                ? `("${searchString}" OR (owner:${searchString}* OR "${searchString}"))`
                : ''
        }${layerTypes} (access:org OR access:public)`;
    }
};

export const buildContentFilter = (layerFilterType: string) => {
    switch (layerFilterType) {
        case 'mapit-geolib':
            return `tags:"GeoLibrary"`;
        case 'mapit-group':
        case 'mapit-org':
            return `-tags:"GeoLibrary"`;
        default:
            return null;
    }
};

export const gisServiceUrlHandler = async (layerUrl: string) => {
    let sourceType = 'arcgisServiceUrl';
    let itemId;
    const isPortalItem = layerUrl.match(/id=(.*)/);
    if (isPortalItem && isPortalItem[1]) {
        const item = await createLayerFromPortalItem(isPortalItem[1]);
        const portalItem = item.portalItem;
        if (portalItem && portalItem.url) {
            sourceType = 'mapItPortal';
            itemId = isPortalItem[1];
        }
    }
    return {
        layerUrl,
        sourceType,
        itemId,
    };
};

export const createMapItLayerItem = (layer: LibraryMapItLayerSaveState): MapItLayerTreeItem => {
    const key = layer.guid ?? `MapItLayer--${nanoid()}`;
    const { serviceItemId, sourceType, url, minScale, maxScale, treeState, name, active } = layer;
    return {
        key,
        id: 0,
        title: name,
        itemType: 'MapItLayer',
        metadata: {
            sourceType: sourceType,
            serviceItemId,
            url,
            minScale,
            maxScale,
            treeState: treeState && JSON.parse(treeState),
        } as MapItLayerMetadata,
        checked: active ?? true,
        legendEnabled: true,
    };
};

const findSavedCheckedState = (parent: MapItLayerTreeItem, childItemKey: string) => {
    const treeState = parent.metadata.treeState;
    if (treeState?.length) {
        return treeState.includes(childItemKey);
    }
};

const createChildren = (layerUrl: string, layers: any[], parent: MapItLayerTreeItem) => {
    return layers.map((item: any) => {
        const key = `${parent.key}--${item.id}`;
        const metadata = parent.metadata;
        const { id, title, name, fullExtent, initialExtent } = item;
        const scale = { minScale: item.minScale, maxScale: item.maxScale };
        return {
            key,
            id,
            title: getLayerName(title || name),
            itemType: 'MapItLayer',
            metadata: {
                ...item,
                url: `${layerUrl}/${id}`,
                extent: fullExtent ?? initialExtent ?? metadata.extent,
                scale: scale,
                geometryType: item.geometryType ?? item?.layerType,
            },
            isLeaf: true,
            checked: findSavedCheckedState(parent, key),
            ownerId: parent.key,
            legendEnabled: parent.legendEnabled,
        } as LibraryLayerTreeItem;
    });
};

export const getLayerFromMetadata = async (
    metadata: MapItLayerMetadata
): Promise<{ mapLayer: Layer; layerUrl: string; portalItemTitle: string; extent: Extent }> => {
    if (metadata.sourceType === 'arcgisServiceUrl') {
        const layer = await Layer.fromArcGISServerUrl({ url: metadata.url });
        return {
            mapLayer: layer,
            layerUrl: metadata.url,
            portalItemTitle: layer.title,
            extent: layer.fullExtent,
        };
    } else {
        const layer = (await createLayerFromPortalItem(metadata.serviceItemId)) as any;
        return {
            mapLayer: layer,
            layerUrl: layer.portalItem.url,
            portalItemTitle: get(layer, 'portalItem.title'),
            extent: layer.portalItem.extent,
        };
    }
};

export const loadMapItLayer = async (
    libraryItem: MapItLayerTreeItem,
    refresh?: boolean
): Promise<MapItLayerTreeItem | undefined> => {
    const metadata = libraryItem.metadata;
    const { originalName } = metadata;
    const children = libraryItem.children;
    if ((children?.length || libraryItem.isLeaf) && !refresh) {
        return;
    }

    const { mapLayer, portalItemTitle, layerUrl, extent } = await getLayerFromMetadata(metadata);
    const itemTitle = libraryItem.title !== originalName ? libraryItem.title : portalItemTitle;

    if (!layerUrl || mapLayer.type == 'vector-tile') {
        return {
            ...libraryItem,
            isLeaf: true,
            title: itemTitle,
            metadata: {
                ...metadata,
                url: layerUrl,
                isLoaded: true,
                refresh: refresh,
                originalName: originalName ? originalName : portalItemTitle,
                extent: extent,
            },
        };
    }
    const layerDefinition = await sendRequest(layerUrl);

    const { layers, fullExtent, initialExtent } = layerDefinition;

    const updatedLibraryItem: MapItLayerTreeItem = {
        ...libraryItem,
        title: itemTitle,
        metadata: {
            ...metadata,
            url: layerUrl,
            extent: fullExtent ?? initialExtent,
            isLoaded: true,
            refresh: refresh,
            originalName: originalName ? originalName : portalItemTitle,
        },
    };

    if (layers) {
        if (layers.length > 1) {
            updatedLibraryItem.children = createChildren(layerUrl, layers, updatedLibraryItem);
        } else if (layers.length === 1) {
            const [layer] = layers;
            updatedLibraryItem.isLeaf = true;
            const updatedMetadata = updatedLibraryItem.metadata;
            const extent = updatedMetadata?.extent ?? layer.fullExtent ?? layer.initialExtent;
            updatedLibraryItem.metadata = {
                ...updatedMetadata,
                extent,
                geometryType: layer.geometryType ?? layer?.layerType,
            };
        }
    }

    return updatedLibraryItem;
};

export const getElevationInfoByLayerType = (geometryType: string | undefined) => {
    switch (geometryType) {
        case 'point':
        case 'esriGeometryPoint':
        case 'esriGeometryMultipoint':
            return 'relative-to-scene';
        case '3DObject':
            return 'absolute-height';
        default:
            return 'on-the-ground';
    }
};

export const getLabelingClasses = (layer: ExtendedLayer) => {
    const labeling = layer.labelingInfo;
    if (!labeling) return null;
    return labeling.map(
        (label) =>
            new LabelClass({
                ...label,
                symbol: label.symbol,
                labelExpressionInfo: label.labelExpressionInfo,
                labelPlacement: label.labelPlacement,
                where: label.where,
                minScale: label.minScale,
                maxScale: label.maxScale,
            })
    );
};

export const fetchMapItLayers = async ({
    url,
    searchQuery,
    offset,
    limit,
    sortBy,
    sortOrder,
    filter,
}: {
    url: string;
    searchQuery: string;
    offset: number;
    limit: number;
    sortBy?: string;
    sortOrder?: string;
    filter?: string | null;
}): Promise<FetchLayerResult | undefined> => {
    const output = await getContent(url, searchQuery, limit, offset, sortBy, sortOrder, filter);
    if (output && output.results && output.results?.length > 0) {
        output.results = output.results.map(function (item: any) {
            return {
                id: item.id,
                name: getLayerName(item.title || item.name),
                type: item.type,
                createdBy: item.owner,
                modifiedBy: item.owner,
                modifiedDate: item.modified || new Date(0).getMilliseconds(),
                usedCount: item.numViews,
                url: item.url,
                extent: item.extent,
                itemType: 'MapItLayer',
                serviceItemId: item.id,
                snippet: item.snippet,
                tags: item.tags,
                groupDesignations: item.groupDesignations,
            };
        });
    }
    return output;
};

async function getContent(
    url: string,
    query: string,
    pageSize: number,
    start: number,
    sortBy?: string,
    sortOrder?: string,
    filter?: string | null
) {
    const params = {
        num: pageSize,
        start: start,
        sortField: sortBy,
        sortOrder: sortOrder,
        q: query,
        filter,
    };
    const response = await sendRequest(url, params);
    return response;
}

function getLayerName(name: string) {
    return name ? name.replace(/_/g, ' ') : '';
}

export const getMapItLayerNodeBounds = (libraryItem: MapItLayerTreeItem) => {
    const metadata = libraryItem.metadata;
    if (metadata?.extent) {
        if (metadata.extent?.type == 'extent') return metadata.extent;
        return new Extent({ ...metadata.extent }) as Geometry;
    }
};

const createLibraryItemTreeState = (libraryItem: LibraryLayerTreeItem) => {
    const items = [] as string[];
    libraryItem.children?.forEach((item) => {
        if (item.checked) {
            items.push(item.key);
        }
    });
    return items;
};

export const createMapItLayerSaveState = (libraryItem: MapItLayerTreeItem) => {
    const treeItems = createLibraryItemTreeState(libraryItem);
    const metadata = libraryItem.metadata;
    return {
        sourceType: metadata.sourceType,
        serviceItemId:
            metadata.sourceType === 'arcgisServiceUrl' ? undefined : metadata.serviceItemId,
        url: metadata.url,
        active: libraryItem.children?.length ? treeItems.length > 0 : libraryItem.checked,
        treeState: treeItems.length ? JSON.stringify(treeItems) : '',
        minScale: metadata.minScale,
        maxScale: metadata.maxScale,
    } as LibraryMapItLayerSaveState;
};

export const shouldActivateLayer = (viewScale: number, featureLayer: ExtendedLayer) => {
    const minScale = get(featureLayer, 'minScale') ?? -Infinity;
    const maxScale = get(featureLayer, 'maxScale') ?? Infinity;

    if ((!minScale && !maxScale) || !viewScale) {
        return true;
    }

    if (!minScale && viewScale >= maxScale) {
        return true;
    }

    if (!maxScale && viewScale <= minScale) {
        return true;
    }

    if (minScale && maxScale && viewScale >= maxScale && viewScale <= minScale) {
        return true;
    }

    return false;
};

export const searchMapItLayerById = (libraryItems: LibraryLayerTreeItem[], layerId: string) => {
    return libraryItems.some((item) => {
        return (
            item.itemType === 'MapItLayer' &&
            item.metadata.sourceType !== 'arcgisServiceUrl' &&
            item.metadata.serviceItemId === layerId
        );
    });
};

const convertCIMPolyline = (symbolJSON: any) => {
    const symbolLayer = symbolJSON.symbolLayers[0];
    const color = symbolLayer?.color;
    const width = symbolLayer?.width;
    const capStyle = symbolLayer.capStyle?.toLowerCase();
    const joinStyle = symbolLayer.joinStyle?.toLowerCase();
    return new SimpleLineSymbol({
        color: color,
        width: width,
        cap: capStyle,
        join: joinStyle,
    });
};

const convertCIMPolygon = (symbolJSON: any) => {
    const fillSymbolLayer = symbolJSON.symbolLayers.find(
        (layer: any) => layer.type === 'CIMSolidFill'
    );
    const color = fillSymbolLayer?.color;
    return new PolygonSymbol3D({
        symbolLayers: [
            new FillSymbol3DLayer({
                material: {
                    color: color,
                },
            }),
        ],
    });
};

const transformCIMStyles = (cimSymbol: CIMSymbol) => {
    const symbolJSON = cimSymbol.toJSON()?.symbol;
    const symbolType = symbolJSON.type;
    if (symbolType == 'CIMLineSymbol') {
        return convertCIMPolyline(symbolJSON);
    } else if (symbolType == 'CIMPolygonSymbol') {
        return convertCIMPolygon(symbolJSON);
    }
    return cimSymbol;
};

/**
 * Improve the renderers compatibility with 3D scene layers.
 * CIM line and polygon symbols are not supported in 3D
 * @see https://developers.arcgis.com/javascript/latest/sample-code/cim-lines-and-polygons/
 */
export const transformCIMRenderer = (renderer: Renderer) => {
    const symbol: any = get(renderer, 'symbol');
    if (get(symbol, 'type') !== 'cim') return renderer;

    const updatedSymbol = transformCIMStyles(symbol as CIMSymbol);
    renderer.set('symbol', updatedSymbol);
    return renderer;
};
