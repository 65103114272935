import Geometry from '@arcgis/core/geometry/Geometry';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import * as distanceOperator from '@arcgis/core/geometry/operators/distanceOperator';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import Query from '@arcgis/core/rest/support/Query';
import { BlockBlobClient } from '@azure/storage-blob';
import { produce } from 'immer';
import Papa, { ParseResult } from 'papaparse';

import { presetColors } from 'components/ColorPicker/ColorPickerComponent';
import { CsvLayerDto } from 'components/csvLayers/CsvLayerPanel';
import { ItemMatch, UnmatchedItem } from 'components/csvLayers/CsvToHighlight/CsvToHighlightSet';
import { BuildingGeometryRef } from 'types/Layers/BuildingGeometryRef';
import { CsvCustomPosition, CsvLayerMetadata, CsvLayerStyle } from 'types/Layers/CsvLayerMetadata';
import { LibraryCsvLayerSaveState } from 'types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem, { CsvLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import endpoints from 'utils/apiClient/endpoints';
import config from 'utils/config';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import { nanoid } from 'utils/idUtils';
import { SourceData } from 'workers/fuzzyCsvMatchShared';
import { OsmOpElementWithIndex } from 'workers/overpass';
import { DEV_PIPELINE_SCENE_LAYER_ID } from './devPipelineHelper';

export const parseCsv = (
    file: string
): Promise<Papa.ParseResult<Record<string, string | undefined>>> => {
    return new Promise<Papa.ParseResult<Record<string, string | undefined>>>((resolve, reject) => {
        Papa.parse<Record<string, string | undefined>>(file, {
            header: true,
            complete: (results) => resolve(results),
            error: (error: Error) => reject(error),
        });
    });
};

export const getDefaultCsvLayerStyles = (): CsvLayerStyle => ({
    showPin: true,
    showLabel: false,
    pinSize: 48,
    pinHeight: 100,
    pinColor: presetColors[0],
    pinIcon: 'location_on',
    pinType: 'generic',
});

export const loadCsvLayerItem = (layer: LibraryCsvLayerSaveState) => {
    return {
        key: layer.guid,
        id: layer.id,
        title: layer.name,
        itemType: 'CsvLayer',
        checked: layer.active,
        children: layer.children?.map((child) => ({ ...child, ownerId: layer.guid })),
        legendEnabled: false,
        showTotalTag: true,
        metadata: {
            layerId: layer.layerId,
            titleField: layer.titleField,
            latitudeField: layer.latitudeField,
            longitudeField: layer.longitudeField,
            labelField: layer.labelField,
            displayFields: layer.displayFields,
            customPositions: layer.customPositions,
            styles: {
                ...getDefaultCsvLayerStyles(),
                ...layer.styles,
            },
        },
    } as LibraryLayerTreeItem;
};

export const createCsvLayerItem = (
    name: string,
    metadata: Omit<CsvLayerMetadata, 'styles'>
): CsvLayerTreeItem => {
    const key = `CsvLayer--${nanoid()}`;

    return {
        key,
        id: metadata.layerId,
        title: name,
        itemType: 'CsvLayer',
        checked: true,
        children: [],
        legendEnabled: false,
        showTotalTag: true,
        metadata: {
            ...metadata,
            styles: { ...getDefaultCsvLayerStyles(), showLabel: !!metadata.labelField },
        },
    };
};

export const createChildren = (
    libraryItem: LibraryLayerTreeItem,
    objectIds: string[],
    titles: string[]
): LibraryLayerTreeItem[] => {
    return objectIds.map((oid, index) => {
        const title = titles.at(index);
        const formattedTitle = title || `Property ${parseInt(oid, 10)}`;
        return {
            key: `${libraryItem.key}--${oid}`,
            id: parseInt(oid),
            title: formattedTitle,
            itemType: 'CsvLayer',
            legendEnabled: libraryItem.legendEnabled,
            checked: libraryItem.checked,
            ownerId: libraryItem.key,
        };
    });
};

export const getCsvLayerSignedUrl = async (id: number): Promise<{ url: string }> => {
    const response = await endpoints.csv.signedUrl.get({
        templateValues: {
            id,
        },
    });

    return await response.json();
};

export const getParsedCsvForLayer = async (
    layerId: number,
    csvLayerMetadata:
        | Pick<CsvLayerMetadata, 'customPositions' | 'latitudeField' | 'longitudeField'>
        | undefined,
    options?: { signal: AbortSignal; abortReason: unknown }
): Promise<ParseResult<Record<string, string | undefined>>> => {
    const { url } = await getCsvLayerSignedUrl(layerId);
    const fetchResponse = await fetch(url, { signal: options?.signal });
    const text = await fetchResponse.text();
    // Check if cancelled before parsing CSV
    if (options?.signal.aborted) {
        throw options.abortReason;
    }
    const parsedCsvData = await parseCsv(text);
    if (
        csvLayerMetadata?.customPositions &&
        csvLayerMetadata.latitudeField &&
        csvLayerMetadata.longitudeField
    ) {
        parsedCsvData.data = applyCustomPositionsToCsvData(
            parsedCsvData.data,
            csvLayerMetadata.customPositions,
            csvLayerMetadata.latitudeField,
            csvLayerMetadata.longitudeField
        );
    }

    if (parsedCsvData.errors.length > 0) {
        const errorRows = parsedCsvData.errors.map((error) => error.row);
        parsedCsvData.data = parsedCsvData.data.filter((_, index) => !errorRows.includes(index));
    }

    return parsedCsvData;
};

export function applyCustomPositionsToCsvData(
    data: Record<string, string | undefined>[],
    customPositions: Record<number, CsvCustomPosition | undefined>,
    latitudeField: string,
    longitudeField: string
) {
    return produce(data, (data) => {
        data?.forEach((row, i) => {
            if (customPositions[i]) {
                row[latitudeField] = String(customPositions[i].latitude);
                row[longitudeField] = String(customPositions[i].longitude);
            }
        });
    });
}

export const saveCsvLayer = async (csvLayer: CsvLayerDto): Promise<number> => {
    const saveResponse = await endpoints.csv.save.post({
        fetchOptions: {
            body: JSON.stringify(csvLayer),
        },
    });

    if (!saveResponse.ok) {
        throw new Error('Failed to save csv layer');
    }

    return parseInt(await saveResponse.text());
};

export const getCsvLayerUploadUrl = async (id: number) => {
    const uploadUrlResponse = await endpoints.csv.uploadUrl.get({
        templateValues: {
            id,
        },
    });

    if (!uploadUrlResponse.ok) {
        throw new Error('Failed to get upload url');
    }

    return await uploadUrlResponse.text();
};

export const uploadCsvToBlobStorage = async (
    sasUrl: string,
    file: File,
    onProgress: (event: { loadedBytes: number }) => void
): Promise<void> => {
    try {
        const blobClient = new BlockBlobClient(sasUrl);
        const metadata = { FileName: file.name };

        await blobClient.uploadData(file, {
            onProgress,
            blobHTTPHeaders: {
                blobContentType: file.type,
                blobContentDisposition: `attachment; filename="${encodeURIComponent(file.name)}"`,
            },
            metadata,
        });
    } catch (error) {
        console.error('Error:', error);
    }
};

export const deleteCsvLayer = async (id: number): Promise<void> => {
    const deleteResponse = await endpoints.csv.remove.delete({
        templateValues: {
            id,
        },
    });

    if (!deleteResponse.ok) {
        throw new Error('Failed to delete csv layer');
    }
};

export const getCsvLayerBounds = async (
    libraryItem: CsvLayerTreeItem
): Promise<Geometry[] | null> => {
    try {
        const metadata = libraryItem.metadata as CsvLayerMetadata;
        const csvData = await getParsedCsvForLayer(libraryItem.id, metadata);

        const points = csvData.data
            .map((rowData): [number, number] => [
                Number(rowData[metadata.longitudeField]),
                Number(rowData[metadata.latitudeField]),
            ])
            .filter((pos) => !isNaN(pos[0]) && !isNaN(pos[1]));

        if (points.length > 0) {
            return points.map((point) => new Point({ longitude: point[0], latitude: point[1] }));
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error in getCsvLayerBounds:', error);
        throw error;
    }
};

interface LayerBuildingWithIndex {
    index: number;
    buildings: { id: number; center: { lat: number; lon: number } }[];
    geometryRef?: BuildingGeometryRef;
}

export const sortFeaturesByDistance = (queryResult: __esri.FeatureSet, point: Point) => {
    return queryResult.features.sort((a, b) => {
        const distanceA = distanceOperator.execute(point, a.geometry);
        const distanceB = distanceOperator.execute(point, b.geometry);
        return distanceA - distanceB;
    });
};

export const searchBuildingsFromFeatureLayers = async (
    layerId: string,
    points: { latitude: number | undefined; longitude: number | undefined }[]
) => {
    const layer = findMapLayer(layerId);
    if (!layer || !(layer instanceof FeatureLayer || layer instanceof SceneLayer)) return [];

    const batchSize = 10;
    const totalRows = points.length;
    const numBatches = Math.ceil(totalRows / batchSize);

    const buildingsWithIndex: LayerBuildingWithIndex[] = [];

    for (let i = 0; i < numBatches; i++) {
        const batchRows = points.slice(i * batchSize, (i + 1) * batchSize);

        const requestPromises: Promise<LayerBuildingWithIndex>[] = [];

        batchRows.forEach((row, index) => {
            const lat = Number(row.latitude);
            const lng = Number(row.longitude);
            const point = new Point({ latitude: lat, longitude: lng });

            const bufferedGeometry = geometryEngine.geodesicBuffer(point, 50, 'meters') as Polygon;

            const query = new Query({
                outFields: ['BlackbirdId'],
                returnGeometry: true,
                geometry: bufferedGeometry,
                spatialRelationship: 'intersects',
            });

            requestPromises.push(
                layer.queryFeatures(query).then((result) => {
                    const sortedResult = sortFeaturesByDistance(result, point);
                    return {
                        index: i * batchSize + index,
                        buildings: sortedResult.map((feature) => ({
                            id: feature.attributes['BlackbirdId'],
                            center: {
                                lat: (feature.geometry as Geometry).extent.center.latitude,
                                lon: (feature.geometry as Geometry).extent.center.longitude,
                            },
                        })),
                    };
                })
            );
        });

        const buildingDataArray = await Promise.all(requestPromises);
        buildingsWithIndex.push(...buildingDataArray);
    }

    return buildingsWithIndex;
};

export const findMatchedAndUnmatchedBuildings = async (
    osmMatches: (OsmOpElementWithIndex | undefined)[],
    points: SourceData[],
    csv: ParseResult<Record<string, string | undefined>>,
    matchIndexByCsvIndex: Array<number | undefined>
) => {
    const buildingEditorMatches = await searchBuildingsFromFeatureLayers(
        config.buildingEditsLayerItemId,
        points
    );

    const devPipelineMatches = await searchBuildingsFromFeatureLayers(
        DEV_PIPELINE_SCENE_LAYER_ID,
        points
    );

    const matched: ItemMatch[] = [];
    const unmatched: UnmatchedItem[] = [];
    for (let i = 0; i < csv.data.length; i++) {
        const matchIndex = matchIndexByCsvIndex[i];
        const osmMatch = typeof matchIndex !== 'undefined' ? osmMatches[matchIndex] : undefined;

        const devPipelineMatch =
            typeof matchIndex !== 'undefined' ? devPipelineMatches[matchIndex] : undefined;

        const buildingEditorMatch =
            typeof matchIndex !== 'undefined' ? buildingEditorMatches[matchIndex] : undefined;

        const bestMatch = buildingEditorMatch?.buildings?.length
            ? buildingEditorMatch
            : devPipelineMatch?.buildings?.length
            ? devPipelineMatch
            : osmMatch;

        const buildingId = Number(bestMatch?.buildings[0]?.id);

        const geometryRef: BuildingGeometryRef = {
            layerType: buildingEditorMatch?.buildings?.length
                ? 'building-edits-layer'
                : devPipelineMatch?.buildings?.length
                ? 'dev-pipeline'
                : 'osm',
            id: buildingId,
        };

        const csvItem = csv.data[i];
        if (bestMatch && geometryRef && Number.isFinite(buildingId)) {
            matched.push({
                csvId: i,
                buildingId: buildingId,
                csvItem: csvItem,
                geometryRef: geometryRef,
            });
        } else {
            unmatched.push({
                id: i,
                csvItem: csvItem,
            });
        }
    }

    return { matched, unmatched };
};

export const findMatchedAndUnmatchedBuildingsWithExclusions = async (
    osmMatches: (OsmOpElementWithIndex | undefined)[],
    points: SourceData[],
    csv: ParseResult<Record<string, string | undefined>>,
    matchIndexByCsvIndex: Array<number | undefined>,
    osmExcludedKeys: number[],
    devPipelineExcludedKeys: number[]
) => {
    const { matched, unmatched } = await findMatchedAndUnmatchedBuildings(
        osmMatches,
        points,
        csv,
        matchIndexByCsvIndex
    );

    const filteredMatched = matched.filter(
        (item) =>
            !osmExcludedKeys.includes(item.buildingId) &&
            !devPipelineExcludedKeys.includes(item.buildingId)
    );

    const filteredUnmatched = unmatched.filter(
        (item) => !osmExcludedKeys.includes(item.id) && !devPipelineExcludedKeys.includes(item.id)
    );

    return { matched: filteredMatched, unmatched: filteredUnmatched };
};
