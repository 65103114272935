import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Layer from '@arcgis/core/layers/Layer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import PortalItem from '@arcgis/core/portal/PortalItem';
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import PolygonSymbol3D from '@arcgis/core/symbols/PolygonSymbol3D';
import numeral from 'numeral';

import { LibrarySubmarketStatisticsSaveState } from 'types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem, {
    SubmarketStatisticsTreeItem,
} from 'types/Layers/LibraryLayerTreeItem';
import {
    SubmarketStatistics,
    SubmarketStatisticsMetadata,
} from 'types/Layers/SubmarketStatisticsMetadata';
import endpoints from 'utils/apiClient/endpoints';
import { nanoid } from 'utils/idUtils';

const colorBuckets = [
    [219, 214, 198, 0.5],
    [177, 177, 180, 0.5],
    [98, 100, 104, 0.5],
    [50, 50, 50, 0.5],
    [0, 0, 0, 0.5],
];

const defaultSymbol = new PolygonSymbol3D({
    symbolLayers: [
        {
            type: 'fill',
            material: { color: colorBuckets[0] },
            outline: { color: [255, 255, 255, 255], size: 2 },
        },
    ],
});

export const createSubmarketLayer = async (id: string) => {
    const layer = (await Layer.fromPortalItem({
        portalItem: new PortalItem({
            id,
        }),
    })) as FeatureLayer;
    layer.id = id;
    layer.legendEnabled = false;
    await applyUniqueRendererToLayer(layer);
    layer.definitionExpression = '1<>1';
    return layer;
};

export const createSubmarketLibraryLayerItem = (
    metadata: SubmarketStatisticsMetadata
): SubmarketStatisticsTreeItem => {
    const key = `SubmarketStatistics--${nanoid()}`;

    const title =
        metadata.markets.length === 1
            ? `${metadata.markets[0]} Submarket Stats`
            : `Submarket Stats`;

    return {
        key,
        id: 0,
        title,
        itemType: 'SubmarketStatistics',
        checked: true,
        metadata,
        legendEnabled: false,
    };
};

export const createSubmarketLayerFromSavedState = (
    layer: LibrarySubmarketStatisticsSaveState
): LibraryLayerTreeItem => {
    const itemType = 'SubmarketStatistics';
    const { guid, name, active, statisticsOptions } = layer;
    const key = guid ?? `${itemType}--${nanoid()}`;

    return {
        id: 0,
        key,
        title: name,
        itemType,
        checked: active ?? true,
        metadata: JSON.parse(statisticsOptions),
        legendEnabled: false,
    };
};

const createLabelingInfo = () => {
    return new LabelClass({
        symbol: {
            type: 'text',
            font: {
                family: 'Arial',
                size: 12,
                weight: 'normal',
            },
            haloColor: 'white',
            haloSize: 1,
        },
        labelExpressionInfo: {
            expression: '$feature.Submarket',
        },
        minScale: 1200000,
    });
};

export const applyUniqueRendererToLayer = async (layer: FeatureLayer) => {
    const labelClass = createLabelingInfo();
    layer.labelingInfo = [labelClass];
    layer.labelsVisible = true;
    layer.popupEnabled = false;
    layer.outFields = ['*'];

    const uniqueValueInfos: UniqueValueInfo[] = [];
    colorBuckets.forEach((color, index) => {
        uniqueValueInfos.push(
            new UniqueValueInfo({
                value: index.toString(),
                symbol: new PolygonSymbol3D({
                    symbolLayers: [
                        {
                            type: 'fill',
                            material: { color },
                            outline: { color: [255, 255, 255, 255], size: 2 },
                        },
                    ],
                }),
            })
        );
    });

    layer.renderer = new UniqueValueRenderer({
        field: 'objectId',
        uniqueValueInfos: uniqueValueInfos,
        valueExpression: '($feature.OBJECTID % 5)',
        defaultSymbol,
    });
    return layer;
};

export const buildDefinitionExpression = (markets: string[]) => {
    if (markets.length === 0) {
        return '';
    }
    return `Market in (${markets.map((market) => `'${market}'`).join(',')})`;
};

const sfFormatter = (value: number) => `${numeral(value).format('0,0')} s.f.`;
const percentFormatter = (value: number) => `${numeral(value * 100).format('0.00')} %`;
const currencyFormatter = (value: number) => `${numeral(value).format('$0.0[0]')} p.s.f.`;

const submarketStatisticsFields = [
    {
        label: 'Existing Supply',
        field: 'areaLettableExistingSum',
        format: sfFormatter,
    },
    {
        field: 'areaUnderDevelopmentSum',
        label: 'Under Development',
        format: sfFormatter,
    },

    { field: 'vacancyDirectSum', label: 'Direct Vacant', format: sfFormatter },
    { field: 'vacancySubleaseSum', label: 'Sublease Vacant', format: sfFormatter },
    { field: 'vacancyTotalSum', label: 'Total Vacant', format: sfFormatter },

    { field: 'absorptionDirectYTDSum', label: 'Direct Absorption YTD', format: sfFormatter },
    { field: 'absorptionSubleaseYTDSum', label: 'Sublease Absorption YTD', format: sfFormatter },
    { field: 'absorptionTotalYTDSum', label: 'Total Absorption', format: sfFormatter },

    { field: 'availabilityDirectSum', label: 'Direct Available', format: sfFormatter },
    { field: 'availabilitySubleaseSum', label: 'Sublease Available', format: sfFormatter },
    {
        field: 'availabilityTotalPercent',
        label: 'Availability',
        format: percentFormatter,
    },

    { field: 'rentAverageDirectAvg', label: 'Direct Rent Avg', format: currencyFormatter },
    { field: 'rentAverageSubleaseAvg', label: 'Sublease Rent Avg', format: currencyFormatter },
    { field: 'rentAverageTotalAvg', label: 'Total Rent Avg', format: currencyFormatter },
];

export const fieldSections = [
    {
        section: 'area',
        fields: ['areaLettableExistingSum', 'areaUnderDevelopmentSum'],
    },
    {
        section: 'vacancy',
        fields: ['vacancyDirectSum', 'vacancySubleaseSum', 'vacancyTotalSum'],
    },
    {
        section: 'absorption',
        fields: ['absorptionDirectYTDSum', 'absorptionSubleaseYTDSum', 'absorptionTotalYTDSum'],
    },
    {
        section: 'availability',
        fields: ['availabilityDirectSum', 'availabilitySubleaseSum', 'availabilityTotalPercent'],
    },
    {
        section: 'rent',
        fields: ['rentAverageDirectAvg', 'rentAverageSubleaseAvg', 'rentAverageTotalAvg'],
    },
];

export interface SubmarketStatisticsField {
    label: string;
    field: string;
    format: (value: number) => string;
}

export const getSubmarketStatisticsField = () => {
    const fieldMap = submarketStatisticsFields.reduce(
        (
            result: Record<string, SubmarketStatisticsField>,
            current: { label: string; field: string; format: (value: number) => string }
        ) => {
            result[current.field] = current;
            return result;
        },
        {}
    );
    return fieldMap;
};

export const fetchSubmarketStatistics = async (
    propertyType: string,
    marketCode: number,
    submarketCode: number
): Promise<SubmarketStatistics | undefined> => {
    try {
        const propertyTypeCode = propertyType.toLowerCase() === 'office' ? 2 : 1;
        const result = await endpoints.submarkets.bySubmarketCode.get({
            templateValues: {
                propertyTypeCode,
                marketCode,
                submarketCode,
            },
        });

        if (result.status !== 200) {
            return undefined;
        }
        const output = await result.json();
        return output;
    } catch (error) {
        console.error(error);
        return undefined;
    }
};
