import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Layer from '@arcgis/core/layers/Layer';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import UniqueValueGroup from '@arcgis/core/renderers/support/UniqueValueGroup';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import Query from '@arcgis/core/rest/support/Query';
import searchBuildingUrl from 'icons/custom/search-building.svg?url';
import searchPinUrl from 'icons/custom/search-pin.svg?url';
import { chain, chunk, compact, includes, map, orderBy, sortBy, startCase } from 'lodash';
import moment from 'moment';

import { buildingClassAllowed, SearchItemTypes } from 'constants/search.constants';
import { MarketFilters } from 'store/searchSlice';
import { AvailabilitySearchResult } from 'types/CspInputs';
import { CspAvailabilityInput } from 'types/cspInputSchemas/CspAvailabilityInputSchema';
import { CspLeaseInput } from 'types/cspInputSchemas/CspLeaseInputSchema';
import { CspSaleInput } from 'types/cspInputSchemas/CspSaleInputSchema';
import { BuildingGeometryRef } from 'types/Layers/BuildingGeometryRef';
import { LibraryPropertySearchSaveState } from 'types/Layers/LibraryItemSaveState';
import LibraryLayerTreeItem from 'types/Layers/LibraryLayerTreeItem';
import { MarketSphereOsmMapping } from 'types/MarketSphereOsmMappings';
import { PropertyMarket } from 'types/PropertyMarket';
import { PropertyResultItem } from 'types/Search/PropertySearchResultProps';
import { AvailabilityFilters } from 'types/searchSchemas/AvailabilityFiltersSchema';
import { DateRangeInput } from 'types/searchSchemas/DateRangeInputSchema';
import { LeaseFilters } from 'types/searchSchemas/LeaseFiltersSchema';
import { MinMaxRangeInput } from 'types/searchSchemas/MinMaxRangeInput';
import { PropertyFilters } from 'types/searchSchemas/PropertyFiltersSchema';
import { SaleFilters } from 'types/searchSchemas/SaleFiltersSchema';
import { BuildingClass, PropertyStatusValues } from 'types/searchSchemas/SearchFieldSchemas';
import endpoints from 'utils/apiClient/endpoints';
import DefaultMap from 'utils/collections/DefaultMap';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import { nanoid } from 'utils/idUtils';
import isDefined from 'utils/isDefined';
import { ordinalize } from 'utils/stringUtils';
import { queryDevPipelineFeatureAttributes } from './devPipelineHelper';
import { createOsmMeshSymbol3D, createUniqueValueGroup } from './osmStylesHelper';
import { createPoint3dSymbol } from './polygonLayerHelper';

export const MARKETSPHERE_PROPERTIES_LAYER_ID = 'UnpinnedController--00';

export const PROPERTIES_SEARCH_MATCHED_UVGROUP_COLOR = [208, 79, 105, 255];
export const PROPERTIES_SEARCH_MATCHED_UVGROUP_KEY = 'properties-search-matched';

export const PROPERTIES_SEARCH_UNMATCHED_UVGROUP_KEY = 'properties-unpinned';
export const PROPERTIES_SEARCH_UNMATCHED_UVGROUP_DEFAULT_COLOR = [206, 214, 216, 255];

interface ListItem {
    value: string;
}

export const MISSING_POLYGONS_PINS_LAYER = 'missing-polygons-pins-layer';
export const MISSING_OVERPASS_RESULT_LAYER = 'missing-overpass-result-layer';

export const SEARCH_PINS_LAYER = 'search-pins-layer';

export const propertyStatusDevPipelineViewList = PropertyStatusValues.filter(
    (item) => item !== 'Existing'
);

export interface MarketSphereBlackbirdMapping {
    marketSpherePropertyId: number;
    blackbirdId?: number;
}

const defaultSearchPinsLayerProperties: __esri.FeatureLayerProperties = {
    source: [],
    objectIdField: 'OBJECTID',
    fields: [
        {
            name: 'OBJECTID',
            type: 'oid',
        },
        {
            name: 'id',
            type: 'string',
        },
        {
            name: 'propertyId',
            type: 'string',
        },
        {
            name: 'marketSpherePropertyId',
            type: 'integer',
        },
        {
            name: 'isMatched',
            type: 'integer',
        },
    ],
    elevationInfo: {
        mode: 'relative-to-scene',
    },
    geometryType: 'point',
    renderer: new UniqueValueRenderer({
        field: 'marketSpherePropertyId',
        defaultSymbol: createPoint3dSymbol(),
        uniqueValueGroups: [] as UniqueValueGroup[],
    }),
    legendEnabled: false,
    spatialReference: {
        wkid: 4326,
    },
    outFields: ['*'],
    popupEnabled: false,
};

export const createMissingPolygonsPinsLayer = () => {
    const missingPolygonsPinsLayerProperties = {
        ...defaultSearchPinsLayerProperties,
        id: MISSING_POLYGONS_PINS_LAYER,
    };
    return new FeatureLayer(missingPolygonsPinsLayerProperties);
};

export const createSearchPinsLayer = () => {
    const searchPinsLayerProperties = {
        ...defaultSearchPinsLayerProperties,
        id: SEARCH_PINS_LAYER,
        renderer: new UniqueValueRenderer({
            field: 'marketSpherePropertyId',
            defaultSymbol: createPoint3dSymbol(searchPinUrl),
            uniqueValueGroups: [] as UniqueValueGroup[],
        }),
    };
    return new FeatureLayer(searchPinsLayerProperties);
};

export const createMissingOverpassResultLayer = () => {
    return new FeatureLayer({
        source: [],
        objectIdField: 'OBJECTID',
        id: MISSING_OVERPASS_RESULT_LAYER,
        fields: [
            {
                name: 'OBJECTID',
                type: 'oid',
            },
        ],
        elevationInfo: {
            mode: 'relative-to-scene',
        },
        geometryType: 'point',
        renderer: new UniqueValueRenderer({
            field: 'OBJECTID',
            defaultSymbol: createPoint3dSymbol(searchBuildingUrl),
            uniqueValueGroups: [] as UniqueValueGroup[],
        }),
        featureReduction: { type: 'selection' },
        legendEnabled: false,
        minScale: 25000,
        spatialReference: {
            wkid: 4326,
        },
        outFields: ['*'],
        popupEnabled: false,
    });
};

export const createPinsGraphics = (
    properties: PropertyResultItem[],
    customAttributes = {} as Record<string, string | number | boolean>
): Graphic[] => {
    return properties.reduce((graphics: Graphic[], item) => {
        const { id, addresses, propertyId } = item;
        const geoLocation =
            addresses?.[0].latitude && addresses?.[0].longitude
                ? {
                      latitude: addresses?.[0].latitude,
                      longitude: addresses?.[0].longitude,
                  }
                : addresses?.[0]?.geolocation;
        const marketSpherePropertyId = Number(getSourcePropertyNumber(item));

        if (geoLocation && marketSpherePropertyId) {
            const { latitude, longitude } = geoLocation;
            const center = new Point({
                latitude,
                longitude,
            });

            const attributes = {
                id,
                propertyId,
                marketSpherePropertyId,
                ...customAttributes,
            };

            graphics.push(
                new Graphic({
                    geometry: center,
                    attributes,
                })
            );
        }

        return graphics;
    }, []);
};

export const createOverpassGraphics = (properties: PropertyResultItem[]): Graphic[] => {
    return properties.map((item: PropertyResultItem) => {
        const geoLocation = item.addresses?.[0]?.geolocation;
        const { latitude, longitude } = geoLocation;
        const center = new Point({
            latitude,
            longitude,
        });

        return new Graphic({
            geometry: center,
        });
    });
};

export const getSearchTypeId = (type: SearchItemTypes) => {
    switch (type) {
        case 'properties':
            return 1;
        case 'availabilities':
            return 3;
        default:
            break;
    }
};

export const initialRange = (field: MinMaxRangeInput) => {
    if (!field) return [0, 0] as [number, number];
    return [field.min, field.max] as [number, number];
};

export const getListValues = (list: ListItem[]) => {
    return toStartCaseList(map(list, 'value'));
};

export const toStartCaseList = (list: string[]) => {
    const startCaseList = map(list, (item: string) => startCase(item));
    return sortBy(compact(startCaseList));
};

export const availabilityName = (availability: AvailabilitySearchResult) => {
    let availabilityName = '';
    const space = availability?.areaMax ? Intl.NumberFormat().format(availability.areaMax) : null;
    const floor = availability?.allFloors
        ? Number(availability.allFloors) + ordinalize(Number(availability.allFloors))
        : null;
    const suite = availability.suite ? `Suite ${availability.suite}` : null;
    if (space) availabilityName = `${space} s.f`;
    if (space && floor) availabilityName = `${availabilityName} on ${floor}`;
    if (space && availability.suite) availabilityName = `${availabilityName}, ${suite}`;
    else if (availability.suite) availabilityName = `${suite}`;
    return availabilityName;
};

export const secondaryTitle = (availability: AvailabilitySearchResult) => {
    if (availability.leaseType && availability.rentAmountAverage)
        return `${availability.leaseType} | ${Intl.NumberFormat().format(
            availability.rentAmountAverage
        )}`;
    if (availability.leaseType) return availability.leaseType;
    if (availability.rentAmountAverage)
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            availability.rentAmountAverage
        );
};

export const formatNumber = (value: number | string | undefined) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const createSaveSearchTreeItem = (
    key: string,
    title: string,
    polygonLayer: Layer,
    searchTypeId: number | undefined,
    searchParameters: {
        bronzeSourceSystem: string;
        input: string;
    }
): LibraryLayerTreeItem => {
    return {
        id: 0,
        key,
        title,
        itemType: 'PropertySearch',
        metadata: {
            name: title,
            searchParameters: searchParameters,
            searchTypeId: searchTypeId,
        },
    };
};

export const defineSearchLayerColor = (type: number) => {
    switch (type) {
        case 1:
            return '#E30713';
        case 3:
            return '#1601FF';
        default:
            break;
    }
};

export const createSaveSearchLayerItem = async (
    layer: LibraryPropertySearchSaveState
): Promise<LibraryLayerTreeItem> => {
    const key = nanoid();
    return {
        key: key,
        id: layer.id,
        title: layer.name,
        itemType: 'PropertySearch',
        metadata: {
            name: layer.name,
            searchParameters: layer.searchParameters,
            searchTypeId: layer.searchTypeId,
        },
    } as LibraryLayerTreeItem;
};

export const getMatchedAndUnmatchedResults = (
    mappings: MarketSphereOsmMapping[],
    searchResults: PropertyResultItem[]
) => {
    const marketSphereIds = extractMSIDsFromSourceSystem(searchResults);

    const mappingIdsSet = new Set(
        mappings.map((mapping) => String(mapping.marketSpherePropertyId))
    );

    const unmatchedIdsSet = new Set(
        marketSphereIds.filter((id) => !mappingIdsSet.has(id.toString())).map((id) => id.toString())
    );
    const unmatchedResults = searchResults.filter((result) => {
        const sourcePropertyNumber = getSourcePropertyNumber(result);
        return sourcePropertyNumber && unmatchedIdsSet.has(sourcePropertyNumber);
    });

    const matchedResults = searchResults.filter((result) => {
        const sourcePropertyNumber = getSourcePropertyNumber(result);
        return sourcePropertyNumber && mappingIdsSet.has(sourcePropertyNumber);
    });

    return { matchedResults, unmatchedResults };
};

export function uvgForSearchMatchedProperties(matchedIds: number[]) {
    const matchedSymbol = createOsmMeshSymbol3D(PROPERTIES_SEARCH_MATCHED_UVGROUP_COLOR);
    const matchedUniqueGroup = createUniqueValueGroup(
        matchedIds,
        PROPERTIES_SEARCH_MATCHED_UVGROUP_KEY,
        matchedSymbol,
        'Search Results'
    );

    return matchedUniqueGroup;
}

export function uvgForMarketsphereProperties(unmatchedIds: number[] = []) {
    const unmatchedSymbol = createOsmMeshSymbol3D(
        PROPERTIES_SEARCH_UNMATCHED_UVGROUP_DEFAULT_COLOR
    );
    const unmatchedUniqueGroup = createUniqueValueGroup(
        unmatchedIds,
        PROPERTIES_SEARCH_UNMATCHED_UVGROUP_KEY,
        unmatchedSymbol,
        'MarketSphere connected'
    );

    return unmatchedUniqueGroup;
}

export const addMissingOverpassResultOnMap = (results: PropertyResultItem[]) => {
    const layer = findMapLayer(MISSING_OVERPASS_RESULT_LAYER) as FeatureLayer;
    layer?.queryFeatures().then(({ features }) => {
        const deleteFeatures = features;
        const addFeatures = results.length > 0 ? createOverpassGraphics(results) : [];
        layer?.applyEdits({ deleteFeatures, addFeatures });
    });
};

export const clearMissingOverpassResult = () => {
    const layer = findMapLayer(MISSING_OVERPASS_RESULT_LAYER) as FeatureLayer;
    layer?.queryFeatures().then(({ features }) => {
        const deleteFeatures = features;
        layer?.applyEdits({ deleteFeatures });
    });
};

export const addMissingPropertiesOnMap = async (results: PropertyResultItem[]) => {
    const layer = findMapLayer(MISSING_POLYGONS_PINS_LAYER) as FeatureLayer;

    if (!layer) {
        return;
    }

    const marketSpherePropertyIds = extractMSIDsFromSourceSystem(results);

    const query = new Query({
        returnGeometry: false,
        outFields: ['*'],
    });

    const allFeatures = (await layer.queryFeatures(query)).features;

    const deleteFeatures = allFeatures.filter(
        (feature) =>
            !marketSpherePropertyIds.some(
                (marketSpherePropertyId) =>
                    feature.attributes.marketSpherePropertyId === marketSpherePropertyId
            )
    );

    const propertiesToAdd = results.filter((result) => {
        const sourceMetadata = result.sourceMetadatas || result.sourceMetadata;
        return !allFeatures.some(
            (feature) =>
                feature.attributes.marketSpherePropertyId ===
                parseInt(sourceMetadata[0].sourcePropertyNumber)
        );
    });

    const addFeatures = createPinsGraphics(propertiesToAdd);

    await layer.applyEdits({ deleteFeatures, addFeatures });
};

export const setMissingPropertiesVisibilityOnMap = async (marketSpherePropertyIds: number[]) => {
    const condition =
        marketSpherePropertyIds.length > 0
            ? `marketSpherePropertyId NOT IN (${marketSpherePropertyIds.join()})`
            : '1=1';
    const missingPolygonsLayer = findMapLayer(MISSING_POLYGONS_PINS_LAYER) as FeatureLayer;
    if (missingPolygonsLayer) {
        missingPolygonsLayer.definitionExpression = condition;
    }
    const searchPinsLayer = findMapLayer(SEARCH_PINS_LAYER) as FeatureLayer;
    if (searchPinsLayer) {
        searchPinsLayer.definitionExpression = condition;
    }
};

export const queryMissingPropertiesLayer = async (
    marketSpherePropertyIds = [] as number[],
    returnGeometry = false
) => {
    const whereClause =
        marketSpherePropertyIds.length > 0
            ? `marketSpherePropertyId IN (${marketSpherePropertyIds.join(',')})`
            : '1=1';
    const query = new Query({
        where: whereClause,
        outFields: ['marketSpherePropertyId'],
        returnGeometry,
    });
    const layer = findMapLayer(MISSING_POLYGONS_PINS_LAYER) as FeatureLayer;
    return layer?.queryFeatures(query);
};

export const addSearchPinsOnMap = async (
    results: PropertyResultItem[],
    mappings: MarketSphereOsmMapping[]
) => {
    const layer = findMapLayer(SEARCH_PINS_LAYER) as FeatureLayer;

    if (!layer) {
        return;
    }

    const { matchedResults, unmatchedResults } = getMatchedAndUnmatchedResults(mappings, results);

    const existingFeatures = await layer.queryFeatures({
        where: `1=1`,
    });
    const deleteFeatures = existingFeatures.features;

    const matchedFeatures = createPinsGraphics(matchedResults, {
        isMatched: 1,
    });
    const unmatchedFeatures = createPinsGraphics(unmatchedResults, {
        isMatched: 0,
    });
    const addFeatures = [...matchedFeatures, ...unmatchedFeatures];
    await layer.applyEdits({ deleteFeatures, addFeatures });
};

// to avoid unnecessary re-renders
const EMPTY_ARRAY: readonly number[] = Object.freeze([]);
const returnSameRefForEmptyArray = (arr: number[]) =>
    (arr.length === 0 ? EMPTY_ARRAY : arr) as number[];

export const findMatchedAndUnmatchedOsmIds = (
    searchResults: PropertyResultItem[],
    marketSphereOsmMapping: MarketSphereOsmMapping[]
) => {
    const matchedOsmIds: number[] = [];
    const unmatchedOsmIds: number[] = [];

    const marketSpherePropertyIds = extractMSIDsFromSourceSystem(searchResults);

    const matchedMarketSphereIdSet = new Set(marketSpherePropertyIds.map((id) => String(id)));

    marketSphereOsmMapping.forEach((osmMapping) => {
        const osmId = osmMapping.osmId;
        const marketSpherePropertyId = osmMapping.marketSpherePropertyId.toString();

        if (matchedMarketSphereIdSet.has(marketSpherePropertyId)) {
            matchedOsmIds.push(osmId);
        } else {
            unmatchedOsmIds.push(osmId);
        }
    });

    return {
        matched: returnSameRefForEmptyArray(matchedOsmIds),
        unmatched: returnSameRefForEmptyArray(unmatchedOsmIds),
    };
};

export type FilterTypes =
    | PropertyFilters
    | AvailabilityFilters
    | LeaseFilters
    | SaleFilters
    | MarketFilters;

export type SubParametersInput = CspAvailabilityInput | CspLeaseInput | CspSaleInput;

export function isMinMax(value: unknown): value is MinMaxRangeInput {
    return value !== null && typeof value === 'object' && 'min' in value && 'max' in value;
}

export function isDateRange(value: unknown): value is DateRangeInput {
    return value !== null && typeof value === 'object' && 'from' in value && 'to' in value;
}

export const filterOsmMappingsByMsIDArray = async (
    msIds: number[]
): Promise<MarketSphereOsmMapping[]> => {
    const results = [];
    for (const idChunk of chunk(msIds, 1000)) {
        const result = await endpoints.marketSphereOsmMapping.filterMs.post({
            fetchOptions: {
                body: JSON.stringify(idChunk),
            },
        });
        results.push(...(await result.json()));
    }

    return results;
};

export const searchOsmMappingsByOsmIdArray = async (
    osmIds: number[]
): Promise<MarketSphereOsmMapping[]> => {
    const result = await endpoints.marketSphereOsmMapping.filterOsm.post({
        fetchOptions: {
            body: JSON.stringify(osmIds),
        },
    });
    return await result.json();
};

function msIdFromResultItem(property: PropertyResultItem): number | undefined {
    const marketSpherePropertyId = getSourcePropertyNumber(property);
    return marketSpherePropertyId ? parseInt(marketSpherePropertyId) : undefined;
}

export const filterMarketSphereOsmMappings = async (properties: PropertyResultItem[]) => {
    const marketSpherePropertyIds = properties.map(msIdFromResultItem).filter(isDefined);

    if (marketSpherePropertyIds.length) {
        return await filterOsmMappingsByMsIDArray(marketSpherePropertyIds);
    } else {
        return [];
    }
};

export async function geometryRefMapForSearchResults(
    propertyResults: PropertyResultItem[]
): Promise<Map<PropertyResultItem, BuildingGeometryRef[]>> {
    async function addOsmRefs(msIdToRefMap: DefaultMap<number, BuildingGeometryRef[]>) {
        const mappings = await filterMarketSphereOsmMappings(propertyResults);
        mappings.forEach((mapping) => {
            msIdToRefMap
                .get(mapping.marketSpherePropertyId)
                .push({ layerType: 'osm', id: mapping.osmId });
        });
    }

    async function addDevPipelineRefs(msIdToRefMap: DefaultMap<number, BuildingGeometryRef[]>) {
        const marketSphereIds = extractMSIDsFromSourceSystem(propertyResults);
        if (marketSphereIds.length === 0) {
            return;
        }

        const query = new Query({
            where: `MarketSpherePropertyId in (${marketSphereIds})`,
            returnGeometry: false,
            outFields: ['*'],
        });
        const devPipelineAttributes = await queryDevPipelineFeatureAttributes(query);
        devPipelineAttributes
            .filter(
                (attributes) => attributes['MarketSpherePropertyId'] && attributes['BlackbirdId']
            )
            .forEach((attributes) =>
                msIdToRefMap
                    .get(Number(attributes['MarketSpherePropertyId']))
                    .push({ layerType: 'dev-pipeline', id: Number(attributes['BlackbirdId']) })
            );
    }

    const msIdToRefMap = new DefaultMap<number, BuildingGeometryRef[]>(() => []);
    await Promise.all([addOsmRefs(msIdToRefMap), addDevPipelineRefs(msIdToRefMap)]);

    const refMap = new Map<PropertyResultItem, BuildingGeometryRef[]>();

    propertyResults
        .map((property: PropertyResultItem) => ({
            result: property,
            msId: msIdFromResultItem(property),
        }))
        .filter(<T extends { msId?: number }>(entry: T): entry is T & { msId: number } =>
            isDefined(entry.msId)
        )
        .forEach((entry) => {
            refMap.set(entry.result, msIdToRefMap.get(entry.msId));
        });
    return refMap;
}

export const getSourcePropertyNumber = (property: PropertyResultItem) => {
    const sourceMetadata = property.sourceMetadatas || property.sourceMetadata;
    return (
        property.sourcePropertyNumber ??
        sourceMetadata?.find((metadata) => metadata.sourceSystem === 'MarketSphere')
            ?.sourcePropertyNumber
    );
};

export const extractMSIDsFromSourceSystem = (properties: PropertyResultItem[]) => {
    const marketSphereIds: number[] = [];
    for (const property of properties) {
        const sourcePropertyNumber = getSourcePropertyNumber(property);
        if (sourcePropertyNumber) {
            marketSphereIds.push(Number(sourcePropertyNumber));
        }
    }
    return marketSphereIds;
};

export const filterBlackbirdIdsForSearchResult = async (
    searchResults: PropertyResultItem[],
    devPipelineLayer?: SceneLayer | FeatureLayer
): Promise<number[]> => {
    const marketSphereIds = extractMSIDsFromSourceSystem(searchResults);
    if (marketSphereIds.length === 0) {
        return [];
    }
    const query = new Query({
        where: `MarketSpherePropertyId in (${marketSphereIds})`,
        returnGeometry: false,
        outFields: ['*'],
    });
    const queryResult = await devPipelineLayer?.queryFeatures(query);
    if (!queryResult) return [];

    return queryResult.features
        .map((feature) => Number(feature.attributes['BlackbirdId']))
        .filter(isFinite);
};

export const filterPropertyTypesBySearchType = (propertyTypes: string[], searchType: string) => {
    let propertyTypeToLower: string[] = propertyTypes.map((item) => item.toLowerCase());
    if (searchType === 'Lease Comps' || searchType === 'Sales') {
        propertyTypeToLower = propertyTypeToLower.flatMap((item) => {
            if (item.includes('industrial')) {
                return ['industrial', 'flex r&d'];
            }
            if (item.includes('office')) {
                return ['office', 'lab'];
            }
            return [item];
        });
        return propertyTypeToLower;
    }

    return propertyTypeToLower;
};

export const getMarketFilterOptions = (
    marketsList: PropertyMarket[],
    propertyTypes: string[],
    searchType = 'Properties'
) => {
    const propertyTypeToLower = filterPropertyTypesBySearchType(propertyTypes, searchType);

    const result = chain(marketsList)
        .orderBy(['market'])
        .filter(
            (item: PropertyMarket) =>
                isNaN(Number(item.market)) &&
                includes(propertyTypeToLower, item.propertyType.toLowerCase())
        )
        .map((item: PropertyMarket) => ({
            label: item.market,
            value: item.market,
        }))
        .uniqBy('value')
        .value();
    return orderBy(result, ['label']);
};

export const orderedBuildingClass = (propertyGrade: BuildingClass[]) => {
    const list: BuildingClass[] = ['Trophy'];
    const propertyGradeList = propertyGrade?.filter(
        (grade: BuildingClass) => buildingClassAllowed.includes(grade) && grade !== 'Trophy'
    );
    return list.concat(propertyGradeList).filter(Boolean);
};

export const searchFormatter = (
    value: string | number | undefined,
    suffix: string = '',
    prefix: string = '',
    tooltip: boolean,
    useThousandSeparator: boolean = true
): string => {
    if (value === undefined) {
        return `${prefix}0 ${suffix}`.trim();
    }

    let numericValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numericValue)) {
        return '';
    }

    numericValue = Math.round((numericValue + Number.EPSILON) * 100) / 100;

    const formattedNumber = useThousandSeparator
        ? new Intl.NumberFormat().format(numericValue)
        : numericValue.toString();

    return tooltip ? `${prefix}${formattedNumber} ${suffix}`.trim() : formattedNumber;
};

export function searchRangeFormatter(
    range: MinMaxRangeInput | undefined,
    suffix: string = '',
    prefix: string = '',
    tooltip: boolean,
    formatNumber = searchFormatter
) {
    const minString =
        range?.min != null ? formatNumber(range.min, suffix, prefix, tooltip) : undefined;
    const maxString =
        range?.max != null ? formatNumber(range.max, suffix, prefix, tooltip) : undefined;
    if (minString != null) {
        if (maxString != null) {
            return `${minString} to ${maxString}`;
        } else {
            return `≥ ${minString}`;
        }
    } else if (maxString != null) {
        return `≤ ${maxString}`;
    }

    return '';
}

export function searchRangeDateFormatter(min?: string | null, max?: string | null) {
    const minString = min && defaultDateFormatter(min);
    const maxString = max && defaultDateFormatter(max);

    if (minString && maxString) return `${minString} to ${maxString}`;
    if (minString) return `≥ ${minString}`;
    if (maxString) return `≤ ${maxString}`;

    return '';
}

export function searchYearRangeFormatter(range: MinMaxRangeInput | undefined) {
    return searchRangeFormatter(range, '', '', true, (year) => String(year));
}

function defaultDateFormatter(dateString: string) {
    return moment(dateString).format('MM-DD-YYYY');
}

export const overrideSearchResult: __esri.GoToOverride = (view, goToParams) => {
    const searchType = goToParams.target.target.getAttribute('Addr_type');

    if (searchType !== 'Locality') {
        return view.goTo({
            target: goToParams.target.target.geometry,
            zoom: 16,
            heading: 0,
            tilt: 0,
        });
    } else {
        return view.goTo({
            target: goToParams.target.target.geometry,
            zoom: 14,
            heading: 30,
            tilt: 75,
        });
    }
};

export const isMissingPolygonsPinsLayer = (layerId: string) => {
    return layerId === MISSING_POLYGONS_PINS_LAYER;
};

export const searchBlackbirdIdsByMarketSphereIds = async (
    marketSpherePropertyIds: number[]
): Promise<MarketSphereBlackbirdMapping[]> => {
    const result = await endpoints.marketSphereOsmMapping.blackbirdIdsByMarketSphereIds.post({
        fetchOptions: {
            body: JSON.stringify(marketSpherePropertyIds),
        },
    });
    return await result.json();
};

export const setSearchPinVisibility = (scale: number, showUnmatchedProperties = false) => {
    const layer = findMapLayer(SEARCH_PINS_LAYER) as FeatureLayer;
    if (!layer) return;

    let expression = '1=1';
    if (scale > 20000) {
        expression = showUnmatchedProperties ? '1=1' : 'isMatched = 1';
    } else {
        expression = showUnmatchedProperties ? 'isMatched = 0' : '1=0';
    }
    layer.definitionExpression = expression;
};

export function isDateTimeRangeDefined(range: DateRangeInput | undefined): range is DateRangeInput {
    return range != null && (range.from != null || range.to != null);
}

export function dateTimeRangeFromValueArray(
    values: string[],
    format: string = 'MM-DD-YYYY'
): DateRangeInput {
    return {
        from: values[0] ? moment(values[0], format).toISOString() : undefined,
        to: values[1] ? moment(values[1], format).toISOString() : undefined,
    };
}
