import { useEffect } from 'react';
import Graphic from '@arcgis/core/Graphic';
import { App } from '@jll/react-ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, uniq } from 'lodash';

import { EXCLUDED_LAYERS } from 'helpers/buildingsExcludeHelper';
import { findBlackbirdIdFromDevPipeline } from 'helpers/devPipelineHelper';
import {
    applyEditsToLayer,
    createBuildingGraphicToEdit,
    createSketchExtrudedPolygon,
    DEFAULT_POLYGON_HEIGHT_IN_METERS,
    deleteFeaturesFromEditsLayer,
    getGraphicHeight,
    isBuildingEditsLayer,
    queryBuildingEditsLayer,
    savePropertyCore,
    setGraphicHeight,
    showAllFeatures,
} from 'helpers/polygonEditorHelper';
import {
    isMissingPolygonsPinsLayer,
    queryMissingPropertiesLayer,
    setMissingPropertiesVisibilityOnMap,
} from 'helpers/searchHelper';
import { useMap } from 'hooks/MapProvider';
import {
    selectDevPipelineAdminExcludedKeys,
    selectOsmAdminExcludedKeys,
    setAndSaveBuildingsExclusion,
} from 'store/buildingsExcludeSettingsSlice';
import { selectLookupByMarketSphereId } from 'store/marketSphereOsmMappingSlice';
import {
    addEditedBlackbirdIds,
    addEditedMarketSpherePropertyIds,
    addGraphicsToUpdate,
    removeEditedBlackbirdPropertyIds,
    removeEditedMarketSpherePropertyIds,
    selectedGraphicLayerId,
    selectedGraphics,
    selectEditedMarketSpherePropertyId,
    selectGraphicsToUpdate,
    selectOpenPolygonEditor,
    setOpenPolygonEditor,
    setSelectedGraphics,
} from 'store/polygonsLayerSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import isDefined from 'utils/isDefined';

export interface BuildingDetail {
    buildingId: number;
    layerType: EXCLUDED_LAYERS | 'building-edits-layer';
}

export const usePolygonEditorActions = () => {
    const dispatch = useAppDispatch();
    const { message } = App.useApp();
    const { buildingEditing } = useFlags();

    const osmMapping = useAppSelector(selectLookupByMarketSphereId);
    const currentLayer = useAppSelector(selectedGraphicLayerId);

    const currentGraphics = useAppSelector(selectedGraphics);
    const graphicsToUpdate = useAppSelector(selectGraphicsToUpdate);
    const marketSpherePropertyIds = useAppSelector(selectEditedMarketSpherePropertyId);
    const osmAdminKeys = useAppSelector(selectOsmAdminExcludedKeys);
    const devPipelineAdminKeys = useAppSelector(selectDevPipelineAdminExcludedKeys);

    const isPolygonEditorOpen = useAppSelector(selectOpenPolygonEditor);

    const { hideMapContextMenu } = useMap();

    useEffect(() => {
        setMissingPropertiesVisibilityOnMap(marketSpherePropertyIds);
    }, [marketSpherePropertyIds]);

    const checkIfBuildingIsEdited = (marketSpherePropertyId: number) => {
        return marketSpherePropertyIds.includes(marketSpherePropertyId);
    };

    const isNewBuilding = () =>
        !currentLayer ||
        isMissingPolygonsPinsLayer(currentLayer) ||
        (currentGraphics[0]?.layer && isMissingPolygonsPinsLayer(currentGraphics[0].layer.id));

    const getMarketSpherePropertyId = () => {
        const graphic = graphicsToUpdate[0] ?? currentGraphics[0];
        if (!graphic) return undefined;
        const attributes = graphic.attributes;
        return attributes?.marketSpherePropertyId ?? attributes?.MarketSpherePropertyId;
    };

    const saveBuildingExclusion = (keys: number[], layerType: EXCLUDED_LAYERS) => {
        switch (layerType) {
            case 'DevPipeline':
            case 'OSM':
                return dispatch(
                    setAndSaveBuildingsExclusion({ buildingIds: keys, layer: layerType })
                );
        }
    };

    const openPolygonEditor = async () => {
        if (currentGraphics?.length) {
            const layerId = currentGraphics[0].layer.id;
            if (isMissingPolygonsPinsLayer(layerId) || isBuildingEditsLayer(layerId)) {
                const editableGraphics: Graphic[] = [];
                currentGraphics.forEach((graphic) => {
                    const { attributes, geometry, layer } = graphic;
                    const height = getGraphicHeight(graphic) ?? DEFAULT_POLYGON_HEIGHT_IN_METERS;
                    editableGraphics.push(
                        new Graphic({
                            geometry,
                            attributes: {
                                ...attributes,
                                MarketSpherePropertyId: getMarketSpherePropertyId(),
                            },
                            layer,
                            symbol: createSketchExtrudedPolygon(height),
                        })
                    );
                });
                dispatch(addGraphicsToUpdate(editableGraphics));
                dispatch(setOpenPolygonEditor(true));
            }
        }
        hideMapContextMenu();
    };

    const onRedraw = async (buildingDetail: BuildingDetail, marketSpherePropertyId: number) => {
        const { buildingId, layerType } = buildingDetail;
        const graphics = [] as Graphic[];
        if (!checkIfBuildingIsEdited(marketSpherePropertyId)) {
            const graphic = await createBuildingGraphicToEdit(
                marketSpherePropertyId,
                DEFAULT_POLYGON_HEIGHT_IN_METERS
            );
            if (graphic) {
                graphics.push(graphic);
                const keys = uniq([
                    ...(layerType == 'OSM' ? osmAdminKeys : devPipelineAdminKeys),
                    buildingId,
                ]) as number[];
                saveBuildingExclusion(keys, layerType as EXCLUDED_LAYERS);
            } else {
                message.error('Redraw building failed');
                onCancelPolygonEditor();
            }
        } else if (marketSpherePropertyId) {
            const features = await queryBuildingEditsLayer([marketSpherePropertyId]);
            if (features?.length) {
                features.forEach((feature) => {
                    const height = feature.attributes.Height ?? DEFAULT_POLYGON_HEIGHT_IN_METERS;
                    feature.symbol = createSketchExtrudedPolygon(height, false);
                    graphics.push(feature);
                });
            }
        }
        if (graphics.length) {
            dispatch(addGraphicsToUpdate(graphics));
            dispatch(setOpenPolygonEditor(true));
        }
    };

    const showRedrawBuildingMenuOption = (
        buildingDetail: BuildingDetail | undefined,
        osmMappedMsId?: number
    ) => {
        if (!buildingDetail || !buildingEditing) return;
        if (buildingDetail?.layerType == 'DevPipeline') {
            return buildingDetail.buildingId;
        } else {
            const marketSpherePropertyId = getMarketSpherePropertyId();
            return osmMappedMsId ?? checkIfBuildingIsEdited(marketSpherePropertyId);
        }
    };

    const showReviewBuildingMatchMenuOption = (buildingDetail: BuildingDetail | undefined) => {
        return buildingDetail?.layerType == 'OSM';
    };

    const showDrawBuildingMenuOption = () => {
        const marketSpherePropertyId = getMarketSpherePropertyId();
        if (!marketSpherePropertyId) return false;
        return buildingEditing && !checkIfBuildingIsEdited(marketSpherePropertyId);
    };

    const isBuildingCreatedOrInCreation = (buildingDetail: BuildingDetail | undefined) => {
        if (!buildingDetail) return false;
        const { layerType, buildingId } = buildingDetail;
        return isPolygonEditorOpen || (layerType === 'building-edits-layer' && !!buildingId);
    };

    const onCancelPolygonEditor = async () => {
        const marketSpherePropertyId = getMarketSpherePropertyId();
        const osmRecord = osmMapping[marketSpherePropertyId];
        const devPipelineId = await findBlackbirdIdFromDevPipeline(marketSpherePropertyId);
        if (osmRecord && !checkIfBuildingIsEdited(marketSpherePropertyId)) {
            const keys = osmAdminKeys.filter((key) => key !== osmRecord?.osmId);
            dispatch(setAndSaveBuildingsExclusion({ buildingIds: keys, layer: 'OSM' }));
        }
        if (devPipelineId) {
            const keys = devPipelineAdminKeys.filter((key) => key !== devPipelineId);
            dispatch(setAndSaveBuildingsExclusion({ buildingIds: keys, layer: 'DevPipeline' }));
        }
        onClosePolygonEditor();
    };

    const onClosePolygonEditor = () => {
        showAllFeatures();
        dispatch(addGraphicsToUpdate([]));
        dispatch(setSelectedGraphics([]));
        dispatch(setOpenPolygonEditor(false));
    };

    const onSavePolygon = async (
        graphics: Graphic[],
        buildingStatus: string | undefined,
        propertyType: string | undefined,
        heightInMeters: number
    ) => {
        const firstGraphic = graphicsToUpdate[0];
        if (!firstGraphic) {
            console.warn('No graphics to update.');
            return;
        }
        message.loading('Saving building drawings...');

        const marketSpherePropertyId = firstGraphic.attributes['MarketSpherePropertyId'];
        const blackbirdId = firstGraphic.attributes['BlackbirdId'];

        if (!marketSpherePropertyId) {
            return;
        }
        graphics.forEach((graphic) => {
            const graphicHeight = getGraphicHeight(graphic);
            graphic.set('attributes', {
                MarketSpherePropertyId: marketSpherePropertyId,
                BlackbirdId: blackbirdId,
            });
            setGraphicHeight(graphic, graphicHeight ?? heightInMeters);
        });
        const output = await savePropertyCore(
            graphics,
            marketSpherePropertyId.toString(),
            blackbirdId
        );
        if (output) {
            const bbId = Number(output);
            graphics.forEach((graphic) => {
                graphic.attributes['BlackbirdId'] = bbId;
                graphic.attributes['BuildingStatus'] = buildingStatus;
                graphic.attributes['PropertyType'] = propertyType;
            });
            try {
                await applyEditsToLayer(graphics, graphicsToUpdate);
                message.success('Building drawings saved successfully.');
            } catch (e) {
                message.error(
                    'Unable to save changes. Please try again or contact support if the issue persists.'
                );
            }
            if (graphics.length) {
                dispatch(addEditedMarketSpherePropertyIds([marketSpherePropertyId]));
                dispatch(addEditedBlackbirdIds([bbId]));
            }
        }
        onClosePolygonEditor();
    };

    const enableMissingPolygonEditing = async (marketSpherePropertyId: number) => {
        const output = await queryMissingPropertiesLayer([marketSpherePropertyId], true);
        if (output?.features?.length) {
            dispatch(setSelectedGraphics(output.features));
        }
    };

    const getRedrawMenuOptionTitle = (marketSpherePropertyId: string | undefined) => {
        if (isEmpty(marketSpherePropertyId)) return '';
        return checkIfBuildingIsEdited(Number(marketSpherePropertyId))
            ? 'Edit Building Geometry'
            : 'Redraw Building';
    };

    const onDeletePolygon = async (graphicsToDelete: Graphic[]) => {
        const objectIds = graphicsToDelete
            .map((graphic) => graphic.attributes['ObjectId'] || graphic.attributes['OBJECTID'])
            .filter(isDefined) as number[];

        const marketSpherePropertyId = getMarketSpherePropertyId();
        const devPipelineId = await findBlackbirdIdFromDevPipeline(marketSpherePropertyId);
        if (objectIds.length) {
            await deleteFeaturesFromEditsLayer(uniq(objectIds));
        }
        if (!marketSpherePropertyId) return;
        const features = await queryBuildingEditsLayer([marketSpherePropertyId]);
        const osmRecord = osmMapping[marketSpherePropertyId];
        if (!features?.length) {
            if (osmRecord && checkIfBuildingIsEdited(marketSpherePropertyId)) {
                const keys = osmAdminKeys.filter((key) => key !== osmRecord?.osmId);
                dispatch(setAndSaveBuildingsExclusion({ buildingIds: keys, layer: 'OSM' }));
            }
            if (devPipelineId) {
                const keys = devPipelineAdminKeys.filter((key) => key !== devPipelineId);
                dispatch(setAndSaveBuildingsExclusion({ buildingIds: keys, layer: 'DevPipeline' }));
                dispatch(removeEditedBlackbirdPropertyIds([devPipelineId]));
            }
            dispatch(removeEditedMarketSpherePropertyIds([marketSpherePropertyId]));
        }
    };

    return {
        openPolygonEditor,
        onRedraw,
        showReviewBuildingMatchMenuOption,
        showRedrawBuildingMenuOption,
        showDrawBuildingMenuOption,
        onCancelPolygonEditor,
        onSavePolygon,
        isBuildingCreatedOrInCreation,
        checkIfBuildingIsEdited,
        enableMissingPolygonEditing,
        getRedrawMenuOptionTitle,
        onDeletePolygon,
        getMarketSpherePropertyId,
        isNewBuilding,
    };
};
