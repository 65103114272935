import { itemTypeToLibraryNamespace } from 'constants/layer.constants';
import {
    LibraryAmenitiesSaveState,
    LibraryCsvLayerSaveState,
    LibraryFolderSaveState,
    LibraryImageSaveState,
    LibraryItemSaveState,
    LibraryKmlLayerSaveState,
    LibraryMapItLayerSaveState,
    LibraryMediaLayerSaveState,
    LibraryOsmHighlightSetSaveState,
    LibraryOverlaySaveState,
    LibraryPropertyViewSaveState,
    LibraryQuickLayerSetSaveState,
    LibrarySubmarketStatisticsSaveState,
    LibraryWidgetLayerSetSaveState,
} from 'types/Layers/LibraryItemSaveState';
import { LibraryLayerItemType } from 'types/Layers/LibraryLayerItemType';
import LibraryLayerTreeItem, { MarketViewTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import config from 'utils/config';
import { DropFileSupportedTypes } from '../store/mapDragDropSlice';
import { createAmenityLibraryItem, getAmenityLayerNodeBounds } from './amenityLayerHelper';
import { getCsvLayerBounds, loadCsvLayerItem } from './csvLayerHelper';
import { createFolderItem } from './folderHelper';
import {
    createImageLayerItem,
    getImageLibrarySaveState,
    getOverlaySetBounds,
} from './imageLayerHelper';
import {
    createKmlLayerSaveState,
    getKmlLayerNodeBounds,
    searchKmlLayerById,
} from './kmlLayerHelper';
import {
    createMediaLayerSaveState,
    createMediaLayerTreeItem,
    getMediaLayerBounds,
    searchMediaLayerById,
} from './libraryMediaLayerHelper';
import {
    createMapItLayerItem,
    createMapItLayerSaveState,
    getMapItLayerNodeBounds,
    searchMapItLayerById,
} from './mapItLayerHelper';
import {
    createMarketViewLayerFromSavedState,
    createMarketViewSaveState,
    getMarketViewBounds,
} from './marketViewLayerHelper';
import {
    createOsmHighlightSetFromSavedState,
    createOsmHighlightSetSaveState,
    getHighlightSetBounds,
} from './osmHighlightSetHelper';
import { createAllPropertiesItem } from './polygonLayerHelper';
import {
    createQuickLayerFromSavedState,
    createQuickLayerSaveState,
    getQuickLayerBounds,
    isQuickLayerButton,
    searchQuickLayerById,
} from './quickLayerHelper';
import { createSubmarketLayerFromSavedState } from './submarketsHelper';
import { createWebsiteOverlayLibraryItem } from './websiteOverlayHelper';
import {
    createWidgetLayerFromSavedState,
    createWidgetLayerSaveState,
} from './widgetLayerSetHelper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const createLibraryLayerItem = (layer: any): LibraryLayerTreeItem | undefined => {
    switch (layer.itemType) {
        case 'UnpinnedController':
            return createAllPropertiesItem(layer);
        case 'KmlLayer':
        case 'Layer':
        case 'MediaLayer':
            return createMediaLayerTreeItem(layer);
        case 'MapItLayer':
            return createMapItLayerItem(layer);
        case 'Amenity':
            return createAmenityLibraryItem(layer);
        case 'CsvLayer':
            return loadCsvLayerItem(layer);
        case 'Image':
            return createImageLayerItem(layer);
        case 'Overlay':
            return createWebsiteOverlayLibraryItem(layer);
        case 'OsmHighlightSet':
            return createOsmHighlightSetFromSavedState(layer);
        case 'Folder':
            return createFolderItem(layer);
        case 'QuickLayer':
            return createQuickLayerFromSavedState(layer);
        case 'WidgetLayer':
            return createWidgetLayerFromSavedState(layer);
        case 'PropertyView':
            return createMarketViewLayerFromSavedState(layer);
        case 'SubmarketStatistics':
            return createSubmarketLayerFromSavedState(layer);
    }
};

export const createLibraryLayerTreeItems = (
    libraryItems: LibraryItemSaveState[]
): LibraryLayerTreeItem[] => {
    const resolvedArray = [];
    for (const libraryItem of libraryItems) {
        const resolvedParent = createLibraryLayerItem(libraryItem);
        if (!resolvedParent) continue;

        const layer = { ...resolvedParent };

        // If the item is a folder, recursively resolve its children
        if (libraryItem.itemType === 'Folder') {
            const resolvedChildren = [];

            const folder = libraryItem as LibraryFolderSaveState;
            for (const child of folder.children ?? []) {
                const resolvedChild = createLibraryLayerItem(child);
                if (resolvedChild) resolvedChildren.push(resolvedChild);
            }
            layer.children = resolvedChildren;
        }
        resolvedArray.push(layer);
    }
    return resolvedArray;
};

export const getLibraryLayerSaveState = (layer: LibraryLayerTreeItem): LibraryItemSaveState => {
    const result = {
        $type: itemTypeToLibraryNamespace(layer.itemType),
        id: Number(layer.id ?? 0),
        name: layer.title,
        itemType: layer.itemType,
        guid: layer.key,
        active: layer.checked,
    };

    // TODO: Handle other types
    const itemType = layer.itemType;
    switch (itemType) {
        case 'Folder':
            return {
                ...result,
                children: layer.children?.map(
                    (child) => getLibraryLayerSaveState(child) as LibraryItemSaveState
                ),
            } as LibraryFolderSaveState;

        case 'Image':
            return {
                ...result,
                ...getImageLibrarySaveState(layer.metadata),
            } as LibraryImageSaveState;
        case 'CsvLayer':
            return {
                ...result,
                children: layer.children,
                ...layer.metadata,
            } as LibraryCsvLayerSaveState;
        case 'PropertyView':
            return {
                ...result,
                ...createMarketViewSaveState(layer as MarketViewTreeItem),
            } as LibraryPropertyViewSaveState;
        case 'PinnedProperties':
        case 'UnpinnedController':
        case 'PropertySearch':
            return Object.assign(result as LibraryPropertyViewSaveState);
        case 'SubmarketStatistics':
            return Object.assign(result as LibrarySubmarketStatisticsSaveState, {
                statisticsOptions: JSON.stringify(layer.metadata),
            });
        case 'OsmHighlightSet':
            return Object.assign(
                result as LibraryOsmHighlightSetSaveState,
                createOsmHighlightSetSaveState(layer)
            );
        case 'Overlay':
            return Object.assign(result as LibraryOverlaySaveState, {
                settings: JSON.stringify(layer.metadata),
            });
        case 'Amenity':
            return Object.assign(result as LibraryAmenitiesSaveState, {
                options: JSON.stringify(layer.children),
            });
        case 'MapItLayer':
            return Object.assign(
                result as LibraryMapItLayerSaveState,
                createMapItLayerSaveState(layer)
            );
        case 'Layer':
        case 'KmlLayer':
            return Object.assign(
                result as LibraryKmlLayerSaveState,
                createKmlLayerSaveState(layer)
            );
        case 'QuickLayer':
            return Object.assign(
                result as LibraryQuickLayerSetSaveState,
                createQuickLayerSaveState(layer)
            );
        case 'WidgetLayer':
            return Object.assign(
                result as LibraryWidgetLayerSetSaveState,
                createWidgetLayerSaveState(layer)
            );
        case 'MediaLayer':
            return Object.assign(
                result as LibraryMediaLayerSaveState,
                createMediaLayerSaveState(layer)
            );
        default:
            throw new Error(`Unknown layer type: ${itemType}`);
    }
};

export const replaceLibraryItemByKey = (
    libraryItems: LibraryLayerTreeItem[],
    key: string,
    replaceItem: LibraryLayerTreeItem
): LibraryLayerTreeItem[] | undefined => {
    const foundIndex = libraryItems.findIndex((item) => item.key == key);
    if (foundIndex !== -1) {
        libraryItems.splice(foundIndex, 1, replaceItem);
        return libraryItems;
    }
    for (const item of libraryItems) {
        if (item.children?.length) {
            const nestedFound = replaceLibraryItemByKey(item.children, key, replaceItem);
            if (nestedFound) {
                return libraryItems;
            }
        }
    }
    return undefined;
};

export const searchLibraryItemByKey = (
    libraryItems: LibraryLayerTreeItem[],
    key: string
): LibraryLayerTreeItem | undefined => {
    const found = libraryItems.find((item) => item.key === key);
    if (found) return found;

    for (const item of libraryItems) {
        if (item.children?.length) {
            const nestedFound = searchLibraryItemByKey(item.children, key);
            if (nestedFound) return nestedFound;
        }
    }

    return undefined;
};

export const uncheckOtherSameTypeItems = (
    checkedNodes: LibraryLayerTreeItem[],
    checkedKeys: string[],
    nodeKey: string,
    itemType: LibraryLayerItemType,
    keyEndsWith?: string
): string[] => {
    const shouldUncheck = (key: string) =>
        key.startsWith(itemType) && key !== nodeKey && (!keyEndsWith || key.endsWith(keyEndsWith));

    const keysToUncheck = new Set<string>();

    checkedNodes.forEach((item) => {
        if (shouldUncheck(item.key)) {
            keysToUncheck.add(item.key);
        }

        if (item.itemType === 'Folder' && item.children) {
            const childKeysToUncheck = item.children.filter((child) => shouldUncheck(child.key));

            if (childKeysToUncheck.length > 0) {
                keysToUncheck.add(item.key); // Unchecking the folder if it contains at least one uncheckable child
            }
        }
    });

    return checkedKeys.filter((key) => !keysToUncheck.has(key));
};

interface LibraryItemPosition {
    libraryItem: LibraryLayerTreeItem;
    pos: string;
}

export const searchLibraryItemByKeyWithPos = (
    libraryItems: LibraryLayerTreeItem[],
    key: string,
    parentPos = '0'
): LibraryItemPosition | undefined => {
    const foundIndex = libraryItems.findIndex((item) => item.key === key);
    if (foundIndex !== -1) {
        const positionString = parentPos === '0' ? `${foundIndex}` : `${parentPos}-${foundIndex}`;
        return { libraryItem: libraryItems[foundIndex], pos: positionString };
    }

    for (const [index, item] of libraryItems.entries()) {
        if (item.children?.length) {
            const childPos = parentPos === '0' ? `${index}` : `${parentPos}-${index}`;
            const nestedFound = searchLibraryItemByKeyWithPos(item.children, key, childPos);
            if (nestedFound) return nestedFound;
        }
    }
    return undefined;
};

export const removeLibraryItemWithKeys = (
    items: LibraryLayerTreeItem[],
    keys: string[]
): LibraryLayerTreeItem[] => {
    return items
        .filter((item) => !keys.includes(item.key))
        .map((item) => {
            if (item.children) {
                item.children = removeLibraryItemWithKeys(item.children, keys);
            }
            return item;
        });
};

export interface TreeItemWithParentsResult {
    value: LibraryLayerTreeItem;
    parent?: TreeItemWithParentsResult;
}

export const findItemWithParents = (
    libraryItems: LibraryLayerTreeItem[],
    key: string,
    parentResult?: TreeItemWithParentsResult
): TreeItemWithParentsResult | undefined => {
    for (const item of libraryItems) {
        if (item.key === key) {
            return { value: item, parent: parentResult };
        }
        if (item.children?.length) {
            const childrenResult = findItemWithParents(item.children, key, {
                value: item,
                parent: parentResult,
            });
            if (childrenResult) {
                return childrenResult;
            }
        }
    }

    return undefined;
};

export const getLibraryItemsByType = <K extends LibraryLayerTreeItem['itemType']>(
    libraryItems: LibraryLayerTreeItem[],
    itemType: K
): Array<Extract<LibraryLayerTreeItem, { itemType: K }>> => {
    const items: Extract<LibraryLayerTreeItem, { itemType: K }>[] = [];

    for (const item of libraryItems) {
        if (item.itemType === itemType) {
            items.push(item as Extract<LibraryLayerTreeItem, { itemType: K }>);
        }
        if (item.children && item.children.length > 0) {
            items.push(...getLibraryItemsByType(item.children, itemType));
        }
    }
    return items;
};

export const hasLibraryItemType = (
    libraryItems: LibraryLayerTreeItem[],
    itemType: LibraryLayerItemType
): boolean => {
    for (const item of libraryItems) {
        if (item.itemType === itemType) {
            return true;
        }
        if (item.children && item.children.length > 0) {
            const hasChildType = hasLibraryItemType(item.children, itemType);
            if (hasChildType) {
                return true;
            }
        }
    }
    return false;
};

export const getLibraryItemBounds = (
    libraryItem: LibraryLayerTreeItem,
    primaryLibraryItem?: LibraryLayerTreeItem
) => {
    switch (libraryItem.itemType) {
        case 'KmlLayer':
            return getKmlLayerNodeBounds(libraryItem);
        case 'MapItLayer':
            return getMapItLayerNodeBounds(libraryItem);
        case 'Amenity':
            return getAmenityLayerNodeBounds(libraryItem);
        case 'OsmHighlightSet':
            return getHighlightSetBounds(libraryItem, primaryLibraryItem);
        case 'Image':
            return getOverlaySetBounds(libraryItem);
        case 'QuickLayer':
            return getQuickLayerBounds(libraryItem);
        case 'CsvLayer':
            return getCsvLayerBounds(libraryItem);
        case 'PropertyView':
            return getMarketViewBounds(libraryItem);
        case 'MediaLayer':
            return getMediaLayerBounds(libraryItem);
    }
};

export const getLibraryItemsCheckedKeys = (libraryItems: LibraryLayerTreeItem[]) => {
    const checkedKeys = [] as string[];
    libraryItems.forEach((libraryItem: LibraryLayerTreeItem) => {
        const metadata = 'metadata' in libraryItem && libraryItem.metadata;
        if (metadata && 'treeState' in metadata && Array.isArray(metadata?.treeState)) {
            checkedKeys.push(...(metadata.treeState as string[]));
        } else if (libraryItem.checked) {
            const children = libraryItem?.children;
            if (children) {
                checkedKeys.push(...getLibraryItemsCheckedKeys(children));
                const allChecked = checkedKeys.length == children.length;
                if (allChecked) {
                    checkedKeys.push(libraryItem.key);
                }
            } else {
                checkedKeys.push(libraryItem.key);
            }
        } else if (libraryItem.children?.length) {
            checkedKeys.push(...getLibraryItemsCheckedKeys(libraryItem.children));
        }
    });
    return checkedKeys;
};

export const updateLibraryItemOnCheck = (libraryItem: LibraryLayerTreeItem, checked: boolean) => {
    const updatedLibraryItem = { checked } as LibraryLayerTreeItem;
    if (libraryItem.children?.length) {
        const updatedChildren = [] as LibraryLayerTreeItem[];
        libraryItem.children.forEach((child: LibraryLayerTreeItem) => {
            updatedChildren.push({ ...child, checked });
        });
        Object.assign(updatedLibraryItem, { children: updatedChildren });
    }
    return updatedLibraryItem;
};

export const handleCheckable = (libraryItems: LibraryLayerTreeItem[]): LibraryLayerTreeItem[] => {
    return libraryItems.map((item) => {
        const children = item.children;
        const isButton = isQuickLayerButton(item);
        const marketViewParent = item.itemType == 'PropertyView' && item?.children?.length;
        const checkable = !isButton && !marketViewParent;
        if (children && Array.isArray(children)) {
            return {
                ...item,
                checkable: checkable,
                children: handleCheckable(children),
            } as LibraryLayerTreeItem;
        } else {
            return {
                ...item,
                checkable: checkable,
            } as LibraryLayerTreeItem;
        }
    });
};

export const filterLibraryItems = (
    searchText: string,
    libraryItems: LibraryLayerTreeItem[],
    layerFilter?: string[],
    sortFilter?: string
) => {
    const filteredItems: LibraryLayerTreeItem[] = [];

    for (const item of libraryItems) {
        const index = item.title?.toString().toLowerCase().indexOf(searchText.toLowerCase());

        let itemTypeMatches = true;
        if (layerFilter && layerFilter.length > 0 && item.itemType) {
            itemTypeMatches = layerFilter.includes(item.itemType);
        }

        if (item.children && Array.isArray(item.children)) {
            const children = filterLibraryItems(searchText, item.children, layerFilter, sortFilter);
            if (children.length) {
                filteredItems.push({ ...item, children });
            } else if (index > -1 && itemTypeMatches) {
                filteredItems.push({ ...item });
            }
        } else if (index > -1 && itemTypeMatches) {
            filteredItems.push({ ...item });
        }
    }

    if (sortFilter === 'crescent') {
        filteredItems.sort((a, b) => (a.title || '').localeCompare(b.title || ''));
    } else if (sortFilter === 'decrescent') {
        filteredItems.sort((a, b) => (b.title || '').localeCompare(a.title || ''));
    } else if (sortFilter === 'visibility') {
        filteredItems.sort((a, b) => {
            if (a.checked && !b.checked) return -1;
            if (!a.checked && b.checked) return 1;
            return 0;
        });
    }

    return filteredItems;
};

interface UploadResponse {
    id: number;
    layerName: string;
}

export const getDropFileType = (dropFile: File): DropFileSupportedTypes | undefined => {
    const csvFiles = ['.csv', '.xls', '.xlsx', '.ods'];
    const kmlFiles = ['.kml', '.kmz'];
    const isExcelFile = csvFiles.some((ext) => dropFile.name.endsWith(ext));
    const isKmlFile = kmlFiles.some((ext) => dropFile.name.endsWith(ext));
    const isImageFile = dropFile.type.startsWith('image');

    let resultType: DropFileSupportedTypes | undefined;
    if (isExcelFile) resultType = 'CSV';
    if (isKmlFile) {
        resultType = 'KML';
    }
    if (isImageFile) {
        resultType = 'IMAGE';
    }

    return resultType;
};

export const uploadLayer = async (
    url: string,
    file: File,
    onUploadProgress?: (progress: number) => void
): Promise<UploadResponse> => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${config.accessToken}`);
        xhr.setRequestHeader('Subscription-Key', config.blackbirdApiSubscriptionKey);
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percent = Math.round((event.loaded / event.total) * 100);
                onUploadProgress && onUploadProgress(percent);
            }
        };

        xhr.addEventListener('load', () => {
            if (xhr.status === 200) {
                const response: UploadResponse = JSON.parse(xhr.response);
                resolve(response);
            } else {
                reject();
            }
        });

        xhr.addEventListener('error', () => {
            reject();
        });

        xhr.send(formData);
    });
};

export const searchLayerInLibraryList = (
    libraryItems: LibraryLayerTreeItem[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
    layerListItem: any
) => {
    switch (layerListItem.itemType) {
        case 'MapItLayer':
            return searchMapItLayerById(libraryItems, layerListItem.id);
        case 'QuickLayer':
            return searchQuickLayerById(libraryItems, layerListItem.id);
        case 'KmlLayer':
            return searchMediaLayerById(libraryItems, layerListItem.id);
        default:
            return searchKmlLayerById(libraryItems, layerListItem.id);
    }
};

export const getAllKeysIfLegendEnabled = (libraryItems: LibraryLayerTreeItem[]): string[] => {
    const keys: string[] = [];
    libraryItems.forEach((item) => {
        if (item.legendEnabled) {
            keys.push(item.key);
        }
        if (item.children) {
            keys.push(...getAllKeysIfLegendEnabled(item.children));
        }
    });
    return keys;
};

export const getChildIdFromKey = (key: string) => {
    return key.split('--')[2];
};

export const getAllLibraryItemsIncludingNested = (
    libraryItems: LibraryLayerTreeItem[]
): LibraryLayerTreeItem[] => {
    const items: LibraryLayerTreeItem[] = [];
    libraryItems.forEach((item) => {
        items.push(item);
        if (item.itemType === 'Folder' && item.children?.length) {
            items.push(...getAllLibraryItemsIncludingNested(item.children));
        }
    });
    return items;
};

export const sanitizeLibraryItemTitle = (title: string) => {
    // Coerce title into a string as some library item types can end up with
    // non-string titles. (Looking at you CSV layers)
    const firstLine = String(title).split('\n')[0];
    if (firstLine.startsWith('-')) {
        return firstLine.slice(1).trim();
    }
    return firstLine.trim();
};

/**
 * Identifies and processes the key of a library item to determine the type.
 *
 * @param {string} key - The unique identifier for the library item, typically structured with segments separated by `--`.
 *
 * @returns {number} - The number of segments in the key. This can be used to infer different types of library items.
 *
 * Example:
 * - For a key `OsmHighlightSet--XxK9paq84ibGdPWMmYIVt--565995475--I5S8V2_sfN17IwgU29lsu`, the function splits by `--` and counts the segments.
 * - If the key contains length equal to 4, it indicates the item is  CSV/T2H item.
 */
export function identifyLibraryItemKey(key: string) {
    if (!key) return;
    const segments = key.split('--');
    return segments.length;
}
